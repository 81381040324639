import { FC } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

export interface AuthLayoutProps {
  className?: string | undefined
  [x: string]: any
}

export const AuthLayout: FC<AuthLayoutProps> = ({
  children,
}: AuthLayoutProps) => {
  return (
    <div className='flex h-full w-full'>
      {children}
      
      <ToastContainer
        position='bottom-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
        className='toast-container'
      />
    </div>
    
  )
}

AuthLayout.defaultProps = {
  className: undefined,
}

export default AuthLayout
