/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  userDetails: {},
  userStatus: null
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetToInitialState(state, action) {
      ;(state.isLoading = false), (state.userDetails = {}), (state.userStatus = null)
    },

    setIsLoadingState(state, { payload }) {
      state.isLoading = payload
    },

    setUserDetailsState(state, { payload }) {
      state.userDetails = payload
    },
    
    setUserStatusState(state, { payload }) {
      state.userStatus = payload
    },
  },
})

export const {
  resetToInitialState,
  setIsLoadingState,
  setUserDetailsState,
  setUserStatusState,
} = userSlice.actions

export const setToInitial =
  (values, cb = () => {}) =>
  (dispatch) => {
    dispatch(resetToInitialState(values))
    return cb(values)
  }

export const setIsLoading =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoadingState(payload))
  }

export const setUserDetails =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setUserDetailsState(value))
  }


  export const setUserStatus =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setUserStatusState(value))
  }

export default userSlice.reducer
