import { useLocation, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import {  useEffect, useState } from "react";
import { Button, DateInputPicker, Input, QuantityPicker } from "components/atoms";
import { dateFormat } from "utils";
import { useGetAppById, useGetTemplateByIdAndAppId, useRequestQuote } from "framework/api/methods";
import dateToReadableString from "utils/dateToReadableString";

export const RequestQuotePage= () => {
  const navigate = useNavigate()
 const params = useParams()
 const location = useLocation();

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }


 useEffect(() => {
  if(location?.state?.updatedImage==='' || location?.state.updatedImage === null){
    navigate(`/designer/${params.id}/personalized/${params.templateId}`)
  }
},[location?.state?.updatedImage])

 const [selectedImage] = useState<any>(location?.state.updatedImage)
 const [selectedTemplateData, setSelectedTemplateData] = useState<any>(null)
 const [formData, setFormData] = useState(
  { 
    qty: 1,
    requiredDate: new Date(),
    instruction: '',
  }
 )

 const { mutate: getTemplateByIdAndAppIdMutate } = useGetTemplateByIdAndAppId()


 const getTemplateByIdAndAppId = () => {
  getTemplateByIdAndAppIdMutate({
    appId: params.id?.toString() || '',
    templateId: params.templateId?.toString() || '',
   }, {
     onSuccess: ({ data: successData }: any) => {
        setSelectedTemplateData(successData?.data[0])
     },
     onError: ({ response: { data: errData }, response }: any) => {
       if(response?.status===405 || response?.status===403 || response?.status===501 ){
        if(response?.status===403 ){
          notify('Please login to continue.', 'error')
        }else{
          notify('Session expired.', 'error')
        }
         setTimeout(()=>{
           navigate('/auth/login')
           localStorage.clear()
         }, 2000);
       }else{
         notify(errData?.message || 'Something went wrong.', 'error')
       }
       
     },
   })
 }

 useEffect(() => {
  if(params.id && params.templateId){
    getTemplateByIdAndAppId()
  }
 }, [params])

 
 const { mutate: requestQuoteMutate, isLoading: requestQuoteIsLoading } = useRequestQuote()

 // request quote api call
 const requestQuote = (isMobile: boolean) => {
  requestQuoteMutate({
    appId:params.id?.toString() || '' ,
    imageURL: selectedImage || '',
    instructions:formData.instruction || '',
    quantity: formData.qty ||  0,
    required_date: formData.requiredDate,
   }, {
     onSuccess: ({ data: successData }: any) => {
      navigate(`/designer/${params.id}/request-sent`,{state:{qty: formData.qty, requiredDate: formData.requiredDate, instruction: formData.instruction ,supplierContact: successData.supplier_contact , qid:successData.quotation_id, supplier_name:successData.supplier_name, user_contact: successData.user_contact , user_email: successData.user_email, user_name: successData.user_name }})
      isMobile ? onSubmitMobile({...formData,...successData}) : onSubmitPC({...formData,...successData})
     },
     onError: ({ response: { data: errData }, response }: any) => {
       if(response?.status===405 || response?.status===403 || response?.status===501 ){
        if(response?.status===403 ){
          notify('Please login to continue.', 'error')
        }else{
          notify('Session expired.', 'error')
        }
         setTimeout(()=>{
           navigate('/auth/login')
           localStorage.clear()
         }, 2000);
       }else{
         notify(errData?.message || 'Something went wrong.', 'error')
       }
     },
   })
 }


 const onSubmitMobile = (formData: any) => {
  let url = `https://wa.me/+${formData?.supplier_contact}`;
  url += `?text=${encodeURI(`Hello ${formData.supplier_name},\n\nYou have received a new quotation request from a customer.\n\nQuotation Number: ${formData?.quotation_id}\nRequired Date: ${dateToReadableString(formData?.requiredDate)}\nQuantity: ${formData?.qty}\nInstructions: ${formData?.instruction}\n\nYou can also view the sample design using the following link: ${window?.location?.origin}/quotation/${formData?.quotation_id}/\n\nPlease review the details and contact the customer.\nName:  ${formData?.user_name}\nContact Number: ${formData?.user_contact}\nEmail:  ${formData?.user_email}`)}&app_absent=0`;
  window.open(url);
};

const onSubmitPC = (formData: any) => {
  let url = `https://web.whatsapp.com/send?phone=+${formData?.supplier_contact}`;
  url += `&text=${encodeURI(`Hello ${formData.supplier_name},\n\nYou have received a new quotation request from a customer.\n\nQuotation Number: ${formData?.quotation_id}\nRequired Date: ${dateToReadableString(formData?.requiredDate)}\nQuantity: ${formData?.qty}\nInstructions: ${formData?.instruction}\n\nYou can also view the sample design using the following link: ${window?.location?.origin}/quotation/${formData?.quotation_id}/\n\nPlease review the details and contact the customer.\nName:  ${formData?.user_name}\nContact Number: ${formData?.user_contact}\nEmail:  ${formData?.user_email}`)}&app_absent=0`;
  window.open(url);
};
 
const [appName, setAppName] = useState(sessionStorage.getItem('app-name'))

const { mutate: getAppByIdMutate, isLoading: getAppByIdIsLoading, } = useGetAppById()

const getAppById = () => {
 getAppByIdMutate({
   appId: params.id?.toString() || ''
  }, {
    onSuccess: ({ data: successData }: any) => {
     setAppName(successData?.data[0]?.app_name)
     sessionStorage.setItem('app-name',successData?.data[0]?.app_name || '')
    },
    onError: ({ response: { data: errData }, response }: any) => {
      if(response?.status===405 || response?.status===403 || response?.status===501 ){
       if(response?.status===403 ){
         notify('Please login to continue.', 'error')
       }else{
         notify('Session expired.', 'error')
       }
        setTimeout(()=>{
          navigate('/auth/login')
          localStorage.clear()
        }, 2000);
      }else{
        notify(errData?.message || 'Something went wrong.', 'error')
      }
      
    },
  })
}

useEffect(() => {
 if(params.id){
   getAppById()
 }
}, [params.id])


  return (
    <div className='flex flex-col bg-black h-full w-full py-4 px-6 md:px-32 md:py-12 text-white'>

      <span className="text-[40px] md:text-[42px] lg:text-[48px] hidden md:flex">{appName}</span>

      <div className="bg-black md:bg-[#292A2C] md:mt-5 h-full flex flex-col md:px-14 md:py-6 rounded-lg w-full overflow-y-auto hidescrl">
        <div className="w-full flex justify-between items-start">
          <i 
            className="ri-arrow-left-line text-[24px] pb-5 md:pb-2" 
            onClick={()=>{
              navigate(-1)
            }}
          />

          <span className="text-[24px] md:hidden flex">{appName}</span>
        </div>

        <div className="text-[32px] leading-tight captions-medium">
          Request quote
        </div>

        <div className="text-[14px] leading-tight captions-font pt-1 font-light">
          You’re just a step away from getting this masterpiece
        </div>

        <div className="flex flex-col md:flex-row overflow-scroll hidescrl mt-10 gap-x-0 md:gap-x-10 gap-y-8 md:gap-y-0">
          <div className="flex gap-x-10 w-full md:w-1/2 items-center">
            <img src={selectedImage} alt='selected-img' className='w-[120px] md:w-auto h-[120px] md:h-full rounded-md bg-white'/>

            <div className="flex flex-col md:hidden">
              <span className="text-sm pb-1 captions-font font-normal">Quantity</span>
              <QuantityPicker min={1} max={9999999} initial={1}  onChange={(value: number)=>{
                setFormData({
                  ...formData,
                  qty: value
                })
              }} />
            </div>
          </div>

          <div className="flex flex-col w-full md:max-w-[350px]">
            <div className="md:flex flex-col hidden w-fit">
              <span className="text-sm pb-1 captions-font font-normal">Quantity</span>
              <QuantityPicker min={1} max={9999999} initial={1} onChange={(value: number)=>{
                setFormData({
                  ...formData,
                  qty: value
                })
              }} />
            </div>

            <div className="mt-3 flex flex-col">
              <div className='text-sm font-normal text-white pb-1'>Required date</div>
            
              <DateInputPicker
               placeholder={dateFormat(new Date())}
                enabled={true}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    requiredDate: e.target.value
                  })
                }}
                value={formData.requiredDate}
                allowEdit={false}
              />
            </div>

            <div className="mt-8 md:mt-3 flex flex-col">
              <Input
                value={formData.instruction}  
                onChange={(e: any)=>{
                  setFormData({
                    ...formData,
                    instruction: e.target.value
                  })
                }}
                isRequired
                placeholder='3 small, 2 large'
                labelValue="Special Instructions"
              />
              
            </div>

            <Button
              appearance='market-red'
              className="hidden lg:flex md:max-w-[350px] mt-8 md:mt-3"
              isBlock
              isLoading={requestQuoteIsLoading}
              disabled={!formData.instruction}
              onClick={() => {
                requestQuote(false);
              }}
              >
              Request Quote
            </Button>

            <Button
              appearance='market-red'
              className="lg:hidden flex md:max-w-[350px] mt-8 md:mt-3"
              isBlock
              isLoading={requestQuoteIsLoading}
              disabled={!formData.instruction}
              onClick={() => {
                requestQuote(true);
              }}
              >
              Request Quote
            </Button>
          </div>
          
        </div>
      </div>  
    </div>
  )
  
}

export default RequestQuotePage
