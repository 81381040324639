import { FC, useEffect, useState } from 'react'
import walletCoin from 'assets/marketPlace/walletCoin.svg'
import { Button, Input, Drawer, Radio, Loader } from 'components/atoms'
import CN from 'classnames'
import { useAddCoinsToWallet, useGetUserWalletBalance, useVerifyOTPForBurnCredit } from 'framework/api/methods'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

export interface WalletDrawerProps {
  isOpen: boolean
  setIsOpen: any
  [x: string]: any
}

export const WalletDrawer: FC<WalletDrawerProps> = ({
  isOpen,
  setIsOpen,
  ...restProps
}: WalletDrawerProps) => {
  const navigate = useNavigate()
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const [isAddCreditView, setIsAddCreditView] = useState(false)
  const [isOtpEnable, setIsOtpEnable] = useState(false)
  const [pointCount, setPointCount] = useState('')
  const [isDialogUser, setIsDIalogUser] = useState(false)
  const [balance, setBalance] = useState('')
  const[otp,setOtp] = useState('')
  const[serverRef,setServerRef] = useState('')
  

  const onSuccessGetUserWalletBalance =(data: any)=>{
    setIsDIalogUser(true)
    setBalance(data.credit_count)
  }

  const onErrorGetUserWalletBalance =({ response: { data: errData }, response }: any)=>{
    if(response?.status===422){
      setIsDIalogUser(false)
    }
    else if(response?.status===405 || response?.status===403 || response?.status===501 ){
      if(response?.status===403 ){
        notify('Please login to continue.', 'error')
      }else{
        notify('Session expired.', 'error')
      }
      setTimeout(()=>{
        navigate('/auth/login')
        localStorage.clear()
      }, 2000);
    }else{
      notify(errData?.message || 'Something went wrong.', 'error')
    }
   
  }

  /** check wallet balance and availability */
  const { refetch:getUserWalletBalance , isRefetching , isLoading } = useGetUserWalletBalance(onSuccessGetUserWalletBalance, onErrorGetUserWalletBalance)

  useEffect(() => {
    isOpen && getUserWalletBalance()
    setIsAddCreditView(false)
    setIsOtpEnable(false)
    setPointCount('')
    setOtp('')
  }, [isOpen])

  const { mutate: addCoinsToWalletMutate , isLoading :addCoinsToWalletIsLoading } = useAddCoinsToWallet()

  const addCoinsToWallet = () => {
    addCoinsToWalletMutate({
      amount: pointCount
    }, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData?.message || 'OTP has been sent successfully', 'success')
        setServerRef(successData.serverRef)
        setIsOtpEnable(true)
        setPointCount('')
      },
      onError: ({ response: { data: errData }, response }: any) => {
        if(response?.status===405 || response?.status===403 || response?.status===501 ){
          if(response?.status===403 ){
            notify('Please login to continue.', 'error')
          }else{
            notify('Session expired.', 'error')
          }
          setTimeout(()=>{
            navigate('/auth/login')
            localStorage.clear()
          }, 2000);
        }else{
          notify(errData?.message || 'Something went wrong.', 'error')
        }
      },
    })
  }

  const { mutate: verifyOTPForBurnCreditMutate , isLoading :verifyOTPForBurnCreditIsLoading } = useVerifyOTPForBurnCredit()

  const verifyOTPForBurnCredit = () => {
    verifyOTPForBurnCreditMutate({
      pin: otp,
      serverRef: serverRef
    }, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData?.message || 'Credit count updated successfully.', 'success')
        setIsOtpEnable(false)
        setPointCount('')
        setOtp('')
        getUserWalletBalance()
      },
      onError: ({ response: { data: errData }, response }: any) => {
        setPointCount('')
        setOtp('')
        if(response?.status===405 || response?.status===403 || response?.status===501 ){
          if(response?.status===403 ){
            notify('Please login to continue.', 'error')
          }else{
            notify('Session expired.', 'error')
          }
          setTimeout(()=>{
            navigate('/auth/login')
            localStorage.clear()
          }, 2000);
        }else{
          notify(errData?.message || 'Something went wrong.', 'error')
        }
      },
    })
  }
  
  return (
    <>
      <Drawer
          isActive={isOpen}
          modalContainerClass='lg:w-[28%] md:w-[50%] w-[70%]'
          isFooterShow={false}
          isHeaderShow={false}
        >
          <div className='w-full px-12 py-6 flex-col flex justify-start items-center captions-font h-fit'>
            <div className="flex flex-col h-full justify-start md:w-full">
              <div className="hidden md:flex justify-start items-center pb-3">
                <i className="ri-arrow-left-line text-[24px] " onClick={()=>{
                    setIsOpen(false);
                  }}/>
              </div>

              <span className="text-[32px]">Your wallet</span>

              <span className="text-[14px] pt-[1px]">Convert to credits.</span>

              {(isRefetching || isLoading) && <Loader className='mt-10' />}

              {!(isRefetching || isLoading) && 
                <div className='flex h-full w-full'>
                  
                  {/* non dialog users */}
                  {!isDialogUser &&
                    <div className='mt-[40%] w-full text-center text-xl font-medium'>
                      Currently available for
                      <br/>
                      Dialog Customers only
                    </div>
                  }

                  {/* dialog users */}
                  
                { isDialogUser && 
                  <div className='flex flex-col w-full items-center justify-start'>
                      <div className={CN("flex flex-col mt-10 text-center text-[18px] font-light",
                        {'!mt-5': isAddCreditView}
                      )}>
                        <span>Credit Balance : { balance || `0`}</span>
                        {/* <span>Star Points : 0</span> */}
                      </div>
                      
                      <img src={walletCoin} 
                        alt='walletCoin' 
                        className='w-[150px] h-[150px] object-contain'
                      />

                      <span className='text-sm font-medium text-center pt-6'>
                        1 Credit = 20 Rs or 20 Starpoints
                      </span>
                     

                      {!isAddCreditView &&
                        <Button appearance="market-red" className='mt-[20px] w-[80%]'
                          onClick={()=>{
                          setIsAddCreditView(true)
                          setOtp('')
                        }}>
                            Add Credits
                        </Button>
                      }
                    
                      {isAddCreditView &&
                        <div className='w-full px-6'>
                        
                        {!isOtpEnable &&
                          <>
                            <Input
                              value={pointCount}  
                              onChange={(e: any)=>{
                                const inputValue = e.target.value;

                                if (/^\d*$/.test(inputValue)) {
                                setPointCount(inputValue)
                                }
                              }}
                              placeholder='Enter credit top-up amount'
                              className='mt-[30px]' 
                            />

                            <div className='flex flex-col pt-6 text-sm'>
                              <span className='mb-2'>Please select you payment method :</span>

                              <Radio labelText='Add to bill' key='cred' name='cred' defaultChecked/>

                              <Radio labelText='Starpoints' key='cred' name='cred' className='mt-1' isDisabled={true} />
                            </div>
                          
                            <div className='flex flex-col w-full'>
                              <Button
                                appearance="market-red" 
                                className='mt-[20px] w-full' 
                                isBlock
                                disabled={!pointCount}
                                isLoading={addCoinsToWalletIsLoading}
                                onClick={()=>{
                                  addCoinsToWallet()
                                }}
                              >
                                Add
                              </Button>

                              <Button 
                                appearance="market-outline" 
                                className='mt-[10px]' 
                                isBlock
                                onClick={()=>{
                                  setIsAddCreditView(false)
                                  setPointCount('')
                              }}>
                                  Cancel
                              </Button>
                            </div>
                          </>
                          }
                          
                          {isOtpEnable && 
                          <>
                            <Input
                              value={otp}  
                              onChange={(e: any)=>{
                                const inputValue = e.target.value
                                  if (/^\d*$/.test(inputValue)) {
                                    setOtp(e.target.value)
                                  }
                              }}
                              placeholder='XXXXXXX'
                              labelValue='Enter OTP that sends to your mobile number'
                              labelClassName='mb-2'
                              className='mt-[30px]' 
                            />

                            <Button
                                appearance="market-red" 
                                className='mt-[20px] w-full' 
                                isBlock
                                isLoading={verifyOTPForBurnCreditIsLoading}
                                disabled={!otp}
                                onClick={()=>{
                                  verifyOTPForBurnCredit()
                                }}
                              >
                                Send
                              </Button>

                              <Button 
                                appearance="market-outline" 
                                className='mt-[10px]' 
                                isBlock
                                onClick={()=>{
                                  setIsOtpEnable(false)
                                  setPointCount('')
                              }}>
                                  Cancel
                              </Button>
                          </>
                          }
                          
                        </div>
                        
                        }
                    </div>
                  }
                </div>
                }
            </div>  
          </div>
      </Drawer>
  </>
  )
}

WalletDrawer.defaultProps = {}

export default WalletDrawer
