import { useQuery } from "react-query";
import { get } from "framework/api/http";

/* Get verify user */
export const getUserWalletBalance = async () => {
    const res = await get(`/credits-api/get_credits`)
    return res?.data || []
  }

/* Use Query as a Hook */
export const useGetUserWalletBalance = (onSuccess: any, onError: any ) => {
    return useQuery('user-wallet-balance', getUserWalletBalance, {
      refetchOnWindowFocus: false,
      enabled: false,
      retry:0,
      onSuccess,
      onError,
    })
  }

export default useGetUserWalletBalance;


