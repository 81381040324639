import { Slider } from "components/molecules";
import dialogLogo from 'assets/marketPlace/dialogLogo.png'
import { Button, Input } from "components/atoms";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useCheckUser } from "framework/api/methods";
import { toast } from "react-toastify";


export const Login= () => {
  const navigate = useNavigate()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isInputValid, setInputValid] = useState(false)

  
  const { mutate: checkUserMutate, isLoading: checkUserIsLoading } = useCheckUser();
  
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }
  // handle input change
  const handleChange = (e: any) => {
    const inputValue = e.target.value;

    if (/^\d*$/.test(inputValue)) {
      const isValidFormat = /^07\d{8}$/.test(inputValue);
    setInputValid(isValidFormat);
    
    setPhoneNumber(e.target.value)
    }
   
  }
  // function to check user exists or not
  const handleCheckUser = () => {
    checkUserMutate({
      mobile: phoneNumber
    }, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData?.message || 'OTP has been sent successfully', 'success')
        navigate('/auth/otp-page', { state: { phoneNumber, ref: successData.serverRef, isNewUser: false } })
      },
      onError: ({ response: { data: errData } }: any) => {
        if (errData?.message === 'User not found!') {
          navigate('/auth/create-profile', { state: { phoneNumber } })
        }else{
          notify(errData?.message || 'Something went wrong.', 'error')
        }
      },
    })
  }


  return (
    <div className='flex flex-1 h-full min-h-full w-full bg-black text-white px-6 py-10'>
      {/* slider */}
      <div className="hidden md:flex w-1/2 h-full flex-col justify-between md:justify-center items-center pb-0 md:pb-10">
        <div className="flex flex-col w-full justify-center items-center">
          <Slider selectedIndex={0}/>
          <img src={dialogLogo} alt='dialogLogo' className='lg:w-[120px] md:[100px] w-[93px] h-fit mt-[18px] md:mt-[30px] lg:mt-[40px]'/>
          <span className="text-[40px] md:text-[42px] lg:text-[48px] mt-[10px]">MarketplaceAI</span>

          <Button appearance="market-red" className="mt-9 flex md:hidden"
            onClick={()=>{
              navigate('/auth/login')
            }}
          >Continue with phone number</Button>
        </div>
        
        <Button appearance="link" className="mt-0" 
          onClick={()=>{
            //
          }}
        >T&C | Privacy Notice</Button>
      </div>

      {/* login form */}
      <div className="w-full md:w-1/2 flex-col flex justify-center items-center captions-font">
        <div className="flex flex-col h-full justify-start md:justify-center md:w-fit">
          <i className="ri-arrow-left-line text-[24px] pb-10 md:hidden" onClick={()=>{
              navigate(localStorage.getItem('recent-path') || '/')
            }}/>
          <span className="text-[32px]">Welcome!</span>

          <span className="text-[14px] pt-[1px]">Enter the below details to get started</span>

          <Input 
            labelValue="Phone number" 
            onChange={handleChange}
            placeholder='07XXXXXXXX'
            value={phoneNumber} 
            className="mt-[120px] md:mt-[60px] w-[320px]"
           />

          <Button 
            appearance="market-red" 
            className="mt-[100px]" 
            isBlock
            disabled={phoneNumber.length !== 10 || !isInputValid}
            isLoading={checkUserIsLoading}
            onClick={()=>{
              phoneNumber && handleCheckUser()
            }}
            >Continue</Button>
        </div>
      </div>
    </div>
  );
};

export default Login
