/* Layouts */
import { AuthLayout } from 'components/layouts/AuthLayout'
import { MainLayout } from 'components/layouts/MainLayout'
import {AppInfo, CreateProfile,CompleteProfile, Login, OTPVerifyPage, Home, MyProfile, MyWallet, QuoteImage} from '../components/pages'
import { CustomizePage, DesignsPage, PersonalizePage, RequestQuotePage, RequestSentPage } from 'components/pages/app'
import { Category1Page } from 'components/pages/app/merchandise/category1Page'
import { Category2Page } from 'components/pages/app/merchandise/category2Page'
import { Category3Page } from 'components/pages/app/merchandise/category3Page'
import { Category4Page } from 'components/pages/app/merchandise/category4Page'


interface Route {
  path: string
  component?: any
  layout?: any
  redirectTo?: string
  isSecured?: boolean
}

export const routes: Route[] = [
  {
    path: '/auth',
    redirectTo: '/auth/login',
  },
  {
    path: '/auth/login',
    component: Login,
    layout: AuthLayout,
  },
  {
    path: '/auth/otp-page',
    component: OTPVerifyPage,
    layout: AuthLayout,
  },
  {
    path: '/auth/create-profile',
    component: CreateProfile,
    layout: AuthLayout,
  },
  {
    path: '/auth/complete-profile',
    component: CompleteProfile,
    layout: AuthLayout,
  },
  {
    path: '/',
    component: Home,
    layout: MainLayout,
    isSecured: false,
  },
  {
    path: '/app-details/:id',
    component: AppInfo,
    layout: MainLayout,
    isSecured: false,
  },
  {
    path: '/my-profile',
    component: MyProfile,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/my-wallet',
    component: MyWallet,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/designer/:id/product',
    component: Category1Page,
    layout: MainLayout,
    isSecured: false,
  },
  {
    path: '/designer/:id/occasion',
    component: Category2Page,
    layout: MainLayout,
    isSecured: false,
  },
  {
    path: '/designer/:id/style',
    component: Category3Page,
    layout: MainLayout,
    isSecured: false,
  },
  {
    path: '/designer/:id/c-4',
    component: Category4Page,
    layout: MainLayout,
    isSecured: false,
  },
  {
    path: '/designer/:id/designs',
    component: DesignsPage,
    layout: MainLayout,
    isSecured: false,
  },
  {
    path: '/designer/:id/personalized/:templateId',
    component: PersonalizePage,
    layout: MainLayout,
    isSecured: false,
  },
  {
    path: '/designer/:id/customized/:templateId',
    component: CustomizePage,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/designer/:id/request-quote/:templateId',
    component: RequestQuotePage,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/designer/:id/request-sent',
    component: RequestSentPage,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/quotation/:qid',
    component: QuoteImage,
    layout: null,
    isSecured: false,
  },
  
]

export default routes
