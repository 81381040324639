/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  category_1: {},
  category_2: {},
  category_3: {},
  category_4: {}
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    resetToInitialState(state, action) {
      ;(state.isLoading = false), (state.category_1 = {}), (state.category_2 = {}), (state.category_3 = {}), (state.category_4 = {})
    },

    setIsLoadingState(state, { payload }) {
      state.isLoading = payload
    },

    setCategory1State(state, { payload }) {
      state.category_1 = payload
    },

    setCategory2State(state, { payload }) {
      state.category_2 = payload
    },

    setCategory3State(state, { payload }) {
      state.category_3 = payload
    },

    setCategory4State(state, { payload }) {
      state.category_4 = payload
    },
    
  },
})

export const {
  resetToInitialState,
  setIsLoadingState,
  setCategory1State,
  setCategory2State,
  setCategory3State,
  setCategory4State
} = appSlice.actions

export const setToInitial =
  (values, cb = () => {}) =>
  (dispatch) => {
    dispatch(resetToInitialState(values))
    return cb(values)
  }

export const setIsLoading =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoadingState(payload))
  }

export const setCategory1 =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setCategory1State(value))
  }

export const setCategory2 =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setCategory2State(value))
  }

export const setCategory3 =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setCategory3State(value))
  }

export const setCategory4 =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setCategory4State(value))
  }
export default appSlice.reducer
