import { FC } from 'react'
import Select from 'react-select'

export interface SimpleSelectProps {
  onChange?: (e: any) => void
  onBlur?: (e: any) => void
  options?: any
  placeholder?: string | undefined
  [x: string]: any
  value?: any
  defaultValue?: any
  disabled?: boolean
  isError?: boolean
  menuPlacement?: string
}

export const SimpleSelect: FC<SimpleSelectProps> = ({
  options,
  onChange,
  onBlur,
  value,
  defaultValue,
  placeholder,
  restProps,
  disabled,
  isError,
  menuPlacement,
}: SimpleSelectProps) => {
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      height: 56,
      borderRadius: 4,
      paddingLeft: 12,
      border: isError && '1px solid red',
      backgroundColor: 'rgb(103,97,97,0.4)',
      outline: '1px solid #676161 !important',
      outlineOffset: 0,
    }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected, isHover }: any) => {
      return {
        ...styles,
        color: isSelected ? '#212121' : '#212121',
        backgroundColor: isSelected ? '#ffe3e9' : 'white'
      }
    },
  }
  return (
    <Select
      menuPlacement={menuPlacement}
      styles={customStyles}
      options={options}
      className='sd-classes-select'
      placeholder={placeholder}
      theme={(theme: any) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: '#ffe3e9', // option Hover colour
          neutral80: 'white', // typing Inside the select
          neutral20: '#676161', // Outline colour
          primary: '#676161',
          neutral30 : '#676161'
        },
      })}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      defaultValue={defaultValue}
      isDisabled={disabled}
      {...restProps}
    />
  )
}

SimpleSelect.defaultProps = {
  className: undefined,
  options: undefined,
  onChange: undefined,
  onBlur: undefined,
  label: undefined,
  value: undefined,
  defaultValue: undefined,
  disabled: false,
  placeholder: 'select...',
  isError: false,
  menuPlacement:'bottom',
}

export default SimpleSelect
