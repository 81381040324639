import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface VerifyOTPInterface {
  data: string
  serverRef: string
}

export const useVerifyOTP = () => {
  const mutate = useMutation(({ data, serverRef }: VerifyOTPInterface) => {
    return postAPI('/user-api/verify_otp', {
      data,
      serverRef,
    })
  })

  return mutate
}

export default useVerifyOTP
