import { Slider } from "components/molecules";
import dialogLogo from 'assets/marketPlace/dialogLogo.png'
import { Button, Input } from "components/atoms";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { DateInputPicker, SimpleSelect } from "components/atoms";
import { useUpdateUserProfile } from "framework/api/methods";
import { toast } from "react-toastify";
import { dateFormat } from "utils";

export const CompleteProfile= () => {
  const navigate = useNavigate()

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const [address, setAddress] = useState('')
  const [birthDate, setBirthDate] = useState<any>()
  const [gender, setGender] = useState<any>(null)
  const [language, setLanguage] = useState<any>(null)

  const { mutate: updateUserProfileMutate, isLoading: updateUserProfileIsLoading } = useUpdateUserProfile()

  // handle user profile updates
  const handleUpdateUserProfile = () => {
    updateUserProfileMutate({
      address: address || '',
      dob: birthDate || '',
      gender: gender?.value || '',
      language: language?.value || ''
    }, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData?.message || 'User details updated!', 'success')
        navigate(localStorage.getItem('recent-path') || '/')
      },
      onError: ({ response: { data: errData }, response }: any) => {
        if(response?.status===405 || response?.status===403 || response?.status===501 ){
          if(response?.status===403 ){
            notify('Please login to continue.', 'error')
          }else{
            notify('Session expired.', 'error')
          }
          setTimeout(()=>{
            navigate('/auth/login')
            localStorage.clear()
          }, 2000);
        }else{
          notify(errData?.message || 'Something went wrong.', 'error')
        }
        
      },
    })
  }


  return (
    <div className='flex flex-1 h-full min-h-full w-full bg-black text-white px-6 py-10'>
      {/* slider */}
      <div className="hidden md:flex w-1/2 h-full flex-col justify-between md:justify-center items-center pb-0 md:pb-10">
        <div className="flex flex-col w-full justify-center items-center">
          <Slider selectedIndex={2}/>
          <img src={dialogLogo} alt='dialogLogo' className='lg:w-[120px] md:[100px] w-[93px] h-fit mt-[18px] md:mt-[30px] lg:mt-[40px]'/>
          <span className="text-[40px] md:text-[42px] lg:text-[48px] mt-[10px]">MarketplaceAI</span>

          <Button appearance="market-red" className="mt-9 flex md:hidden"
            onClick={()=>{
              navigate('/auth/login')
            }}
          >Continue with phone number</Button>
        </div>
        
        <Button appearance="link" className="mt-0" 
          onClick={()=>{
            //
          }}
        >T&C | Privacy Notice</Button>
      </div>

      {/* complete profile form */}
      <div className="w-full md:w-1/2 flex-col flex justify-center items-center captions-font">
        <div className="flex flex-col h-full justify-start md:justify-center md:w-fit">
          <div className="flex justify-between items-center pb-10 md:hidden">
            <i className="ri-arrow-left-line text-[24px] " onClick={()=>{
                navigate(-1)
              }}/>

            <span className="underline text-sm cursor-pointer" onClick={()=>{navigate(localStorage.getItem('recent-path') || '/')}}>Skip</span>
          </div>

          <div className="hidden md:flex justify-end items-center pb-10">
            <span className="underline text-sm cursor-pointer" onClick={()=>{navigate(localStorage.getItem('recent-path') || '/')}}>Skip</span>
          </div>
          <div className='flex flex-col overflow-y-auto hidescrl'>
            <span className="text-[32px]">Complete profile</span>

            <span className="text-[14px] pt-[1px]">Enter the below details to complete your profile.</span>

            <Input labelValue="Address" value={address} onChange={(e)=>{
              setAddress(e.target.value)
            }} className="mt-[120px] md:mt-[60px] w-[320px]"/>


            <div className="mt-3 flex flex-col">
              <div className='text-sm font-normal text-white pb-1'>Date of Birth</div>
            
              <DateInputPicker
                placeholder={dateFormat(new Date())}
                enabled={true}
                onChange={(e) => setBirthDate(e.target.value)}
                value={birthDate}
                allowEdit={false}
              />
            </div>

            <div className="mt-3">
              <div className='text-sm font-normal text-white pb-1'>Gender</div>

              <SimpleSelect 
                placeholder="Select..."
                value={gender} 
                onChange={(e)=>{
                  setGender(e)
                }}
                options={[{label: 'Male', value:'Male'}, {label: 'Female', value:'Female'}, {label: 'Other', value:'Other'}]} 
              />
            </div>

            <div className="mt-3">
              <div className='text-sm font-normal text-white pb-1'>Language preference</div>

              <SimpleSelect 
                menuPlacement='top'
                placeholder="Select..."
                value={language}  
                onChange={(e)=>{
                  setLanguage(e)
                }}
                options={[{label: 'English', value:'English'}, {label: 'Sinhala', value:'Sinhala'}]} 
              />
            </div>

            
          <Button 
            appearance="market-red" 
            className="mt-[30px]" 
            disabled={!address || !birthDate || !gender || !language}
            isBlock
            isLoading={updateUserProfileIsLoading}
            onClick={()=>{
              handleUpdateUserProfile()
            }}
            >Continue</Button>
          </div>
         </div> 
      </div>
    </div>
  );
};

export default CompleteProfile
