import React, { useState } from 'react';
import CN from 'classnames'

interface QuantityPickerProps {
  min?: number;
  max?: number;
  initial?: number;
  onChange?: (value: number) => void;
  className?: string;
}

export const QuantityPicker: React.FC<QuantityPickerProps> = ({ min = 1, max = 10, initial = 1, onChange, className }) => {
  const [quantity, setQuantity] = useState(initial);

  const increment = () => {
    if (quantity < max) {
      const newQuantity = quantity + 1;
      setQuantity(newQuantity);
      onChange?.(newQuantity);
    }
  };

  const decrement = () => {
    if (quantity > min) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      onChange?.(newQuantity);
    }
  };

  return (
    <div className={CN("flex items-center space-x-4 border border-[#676161] rounded h-10 ",className)}>
      <button
        onClick={decrement}
        className="px-2 py-1 text-sm text-white disabled:text-neutral-300"
        disabled={quantity <= min}
      >
        -
      </button>
      <span className='text-sm min-w-[20px] text-center'>{quantity}</span>
      <button
        onClick={increment}
        className="px-2 py-1 text-sm text-white disabled:text-neutral-300"
        disabled={quantity >= max}
      >
        +
      </button>
    </div>
  );
};

export default QuantityPicker;