import { Button, Input } from "components/atoms";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { DateInputPicker, SimpleSelect } from "components/atoms";
import { setUserDetails } from "store/reducers/user/userSlice";
import { useGetVerifyUserToken, useUpdateUserProfile } from "framework/api/methods";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { dateFormat } from "utils";

export const MyProfile= () => {
  const navigate = useNavigate()

  const token = localStorage.getItem('token')
  
  const onSuccessVerifyToken =(data: any)=>{
    const userDetails = data?.userData
    setAddress(userDetails?.address || '')
    userDetails.gender && setGender({label:userDetails.gender, value:userDetails.gender})
    userDetails.language && setLanguage({label:userDetails.language, value:userDetails.language})
    setBirthDate(userDetails.dob || null)
  }

  const onErrorVerifyToken =()=>{
    localStorage.clear()
    navigate('/auth/login')
  }


  /** Get all cards API */
  const { refetch:getVerifyToken  } = useGetVerifyUserToken(onSuccessVerifyToken,onErrorVerifyToken, false)

  useEffect(() => {
    token && getVerifyToken()
  }, [token])



  const [address, setAddress] = useState('')
  const [birthDate, setBirthDate] = useState<any>()
  const [gender, setGender] = useState<any>(null)
  const [language, setLanguage] = useState<any>(null)

  
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }


  const { mutate: updateUserProfileMutate, isLoading: updateUserProfileIsLoading } = useUpdateUserProfile()

  // handle user profile updates
  const handleUpdateUserProfile = () => {
    updateUserProfileMutate({
      address: address || '',
      dob: birthDate || '',
      gender: gender?.value || '',
      language: language?.value || ''
    }, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData?.message || 'User details updated!', 'success')
      },
      onError: ({ response: { data: errData }, response }: any) => {
        if(response?.status===405 || response?.status===403 || response?.status===501 ){
          if(response?.status===403 ){
            notify('Please login to continue.', 'error')
          }else{
            notify('Session expired.', 'error')
          }
          setTimeout(()=>{
            navigate('/auth/login')
            localStorage.clear()
          }, 2000);
        }else{
          notify(errData?.message || 'Something went wrong.', 'error')
        }
        
      },
    })
  }

  return (
    <div className='flex flex-1 h-full min-h-full w-full bg-black text-white px-6 py-5'>
      {/* my profile form */}
      <div className="w-full md:w-full flex-col flex justify-center items-center captions-font">
        <div className="flex flex-col h-full justify-start md:justify-center md:w-fit">
          <div className="flex justify-start items-center pb-10">
            <i className="ri-arrow-left-line text-[24px] " onClick={()=>{
                navigate(-1)
              }}/>
          </div>
          <div className='flex flex-col overflow-y-auto h-full hidescrl'>
            <span className="text-[32px]">Your profile</span>

            <span className="text-[14px] pt-[1px]">Enter the below details to complete your profile.</span>

            <Input labelValue="Address" value={address} onChange={(e)=>{
              setAddress(e.target.value)
            }} className="mt-[100px] md:mt-[60px] w-[320px]"/>


            <div className="mt-3 flex flex-col">
              <div className='text-sm font-normal text-white pb-1'>Date of Birth</div>
            
              <DateInputPicker
                placeholder={dateFormat(new Date())}
                enabled={true}
                onChange={(e) => setBirthDate(e.target.value)}
                value={birthDate}
                allowEdit={false}
              />
            </div>

            <div className="mt-3">
              <div className='text-sm font-normal text-white pb-1'>Gender</div>

              <SimpleSelect 
                label="Gender"
                placeholder="Select..."
                value={gender} 
                onChange={(e)=>{
                  setGender(e)
                }}
                options={[{label: 'Male', value:'Male'}, {label: 'Female', value:'Female'}, {label: 'Other', value:'Other'}]} 
              />
            </div>

            <div className="mt-3">
              <div className='text-sm font-normal text-white pb-1'>Language preference</div>

              <SimpleSelect 
                label="Gender"
                placeholder="Select..."
                value={language}  
                onChange={(e)=>{
                  setLanguage(e)
                }}
                options={[{label: 'English', value:'English'}, {label: 'Sinhala', value:'Sinhala'}]} 
              />
            </div>

            
          <Button appearance="market-red" className="mt-[30px]" isBlock
             isLoading={updateUserProfileIsLoading}
             disabled={!address || !birthDate || !gender || !language}
             onClick={()=>{
               handleUpdateUserProfile()
             }}
            >Continue</Button>
          </div>
          </div>
        </div>
    </div>
  );
};

export default MyProfile
