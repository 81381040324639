import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface AddCoinsToWalletInterface {
  amount: string
}

export const useAddCoinsToWallet = () => {
  const mutate = useMutation(({ amount }: AddCoinsToWalletInterface) => {
    return get(`/credits-api/add_coin_with_credits/${amount}`)
  })

  return mutate
}

export default useAddCoinsToWallet
