import { useLocation, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { Fragment, useEffect, useState } from "react";
import { Button } from "components/atoms";
import { useGenerateAITemplate, useGetAppById } from "framework/api/methods";

export const CustomizePage= () => {
  const navigate = useNavigate()
 const params = useParams()
 const location = useLocation();

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

useEffect(() => {
  if(location?.state?.promptMessage===''){
    navigate(`/designer/${params.id}/personalized/${params.templateId}`)
  }
},[location?.state?.promptMessage])

  const placeholders: any = [];
  const updatedText = location?.state?.promptMessage.replace(/\[(.*?)\]/g, (match: any, p1: any) => {
    placeholders.push(p1);
    return ''; // Remove the bracketed text from the original string
  });

  
  const [inputValues, setInputValues] = useState(['', '', '', '', '']); // Initial values for input fields
  const [segments] = useState<string[]>(updatedText?.split('****'));
  
  const [finalText, setFinalText] = useState<string | undefined>('');


 useEffect(() => {
  if(sessionStorage.getItem('category_1')===null){
    navigate(`/designer/${params.id}/product`)
  }
 }, [sessionStorage])

 const handleInputChange = (index: any, value: any) => {
  const newInputValues = [...inputValues];
  newInputValues[index] = value;
  setInputValues(newInputValues);

};

useEffect(() => {
    let processedText = location?.state?.promptMessage;
    inputValues.forEach((value, index) => {
      processedText = processedText.replace(/\*\*\*\*\[\w+\]/, value);
    });
    setFinalText(processedText);
  
}, [inputValues])

const calculateInputWidth = (value: any) => {
  return value?.length * 8 + 35;
};




const { mutate: generateAITemplateMutate, isLoading: generateAITemplateIsLoading } = useGenerateAITemplate()

// generate ai template api call
const generateAITemplate = () => {
  generateAITemplateMutate({
  user_prompt: finalText || '',
  date_time: new Date()
  }, {
    onSuccess: ({ data: successData }: any) => {
     navigate(`/designer/${params.id}/personalized/${params.templateId}`,{ state: { AIImage: successData }})
    },
    onError: ({ response: { data: errData }, response }: any) => {
      if(response?.status===405 || response?.status===403 || response?.status===501 ){
       if(response?.status===403 ){
         notify('Please login to continue.', 'error')
       }else{
         notify('Session expired.', 'error')
       }
        setTimeout(()=>{
          navigate('/auth/login')
          localStorage.clear()
        }, 2000);
      }else{
        notify(errData?.message || 'Something went wrong.', 'error')
      }
    },
  })
}

const [appName, setAppName] = useState(sessionStorage.getItem('app-name'))

const { mutate: getAppByIdMutate, isLoading: getAppByIdIsLoading, } = useGetAppById()

const getAppById = () => {
 getAppByIdMutate({
   appId: params.id?.toString() || ''
  }, {
    onSuccess: ({ data: successData }: any) => {
     setAppName(successData?.data[0]?.app_name)
     sessionStorage.setItem('app-name',successData?.data[0]?.app_name || '')
    },
    onError: ({ response: { data: errData }, response }: any) => {
      if(response?.status===405 || response?.status===403 || response?.status===501 ){
       if(response?.status===403 ){
         notify('Please login to continue.', 'error')
       }else{
         notify('Session expired.', 'error')
       }
        setTimeout(()=>{
          navigate('/auth/login')
          localStorage.clear()
        }, 2000);
      }else{
        notify(errData?.message || 'Something went wrong.', 'error')
      }
      
    },
  })
}

useEffect(() => {
 if(params.id){
   getAppById()
 }
}, [params.id])

  return (
    <div className='flex flex-col bg-black h-full w-full py-4 px-6 md:px-32 md:py-12 text-white'>

      <span className="text-[40px] md:text-[42px] lg:text-[48px] hidden md:flex">{appName}</span>

      <div className="bg-black md:bg-[#292A2C] md:mt-5 h-full flex flex-col md:px-14 md:py-6 rounded-lg w-full overflow-y-auto hidescrl">
          <div className="w-full flex justify-between items-start">
            <i 
              className="ri-arrow-left-line text-[24px] pb-5 md:pb-2" 
              onClick={()=>{
                navigate(-1)
              }}
            />

            <span className="text-[24px] md:hidden flex">{appName}</span>
          </div>

        <div className="text-[32px] leading-tight captions-medium">
          Customize
        </div>

        <div className="text-[14px] leading-tight captions-font pt-1 font-light">
          Use your available credits to get your designs customized
        </div>

        <div className="flex flex-col md:flex-row overflow-scroll hidescrl mt-10 gap-x-0 md:gap-x-10 gap-y-14 md:gap-y-0">
          <div className='w-full flex bg-[#292A2C] h-auto !px-3 py-3 shadow-md flex-wrap items-center overflow-x-auto rounded border border-[#FF859F] captions-medium font-normal'>
            <p className="captions-medium">
              {segments && segments.map((segment, index) => (
                <Fragment key={index}>
                  {segment}
                  {index < segments.length - 1 && (
                    <input
                      maxLength={16}
                      value={inputValues[index]}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      type="text"
                      placeholder={placeholders[index]}
                      style={{ minWidth: '100px', width: calculateInputWidth(inputValues[index]) + 'px' }}
                      className='mr-1 pr-1 outline-none !border-b !border-b-[#FF204E] !rounded-none bg-[#292A2C] text-white'
                    />
                  )}
                </Fragment>
              ))}
            </p>
          </div>

          <Button
            appearance='market-red'
            className="md:max-w-[350px]"
            isBlock
            isLoading={generateAITemplateIsLoading}
            onClick={() => {
              generateAITemplate();
            }}
            >
            Generate Design
          </Button>
        </div>
      </div>  
    </div>
  )
  
}

export default CustomizePage
