import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface VerifyOTPInterface {
  pin: string
  serverRef: string
}

export const useVerifyOTPForBurnCredit = () => {
  const mutate = useMutation(({ pin, serverRef }: VerifyOTPInterface) => {
    return postAPI('/credits-api/send_charge_submit_pin', {
      pin,
      serverRef,
    })
  })

  return mutate
}

export default useVerifyOTPForBurnCredit


