import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface RateAppInterface {
  appId: string
  rate_count: string
}

export const useAddRatingsForApp = () => {
  const mutate = useMutation(({ appId, rate_count }: RateAppInterface) => {
    return postAPI('/ratings-api/add_ratings', {
      appId,
      rate_count,
    })
  })

  return mutate
}

export default useAddRatingsForApp


