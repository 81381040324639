import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";
import preview1 from 'assets/marketPlace/preview-1.png'
import { useGetAppById } from "framework/api/methods";
import { useEffect, useState } from "react";

export const Category4Page= () => {
  const navigate = useNavigate()
 const params = useParams()

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const [categoryData, setCategoryData] = useState({
    name: '',
    subHeading: '',
    data: []
  })

  
 const [appName, setAppName] = useState(sessionStorage.getItem('app-name'))
  
 const { mutate: getAppByIdMutate, isLoading: getAppByIdIsLoading, } = useGetAppById()


 const getAppById = () => {
  getAppByIdMutate({
    appId: params.id?.toString() || ''
   }, {
     onSuccess: ({ data: successData }: any) => {
    
      const data = JSON.parse(successData?.data[0]?.category_4)
      setAppName(successData?.data[0]?.app_name)
      sessionStorage.setItem('app-name',successData?.data[0]?.app_name || '')

      setCategoryData({
        ...categoryData,
        name: data[0]?.name,
        subHeading: '',
        data: data[0]?.data
      })
     },
     onError: ({ response: { data: errData }, response }: any) => {
       if(response?.status===405 || response?.status===403 || response?.status===501 ){
        if(response?.status===403 ){
          notify('Please login to continue.', 'error')
        }else{
          notify('Session expired.', 'error')
        }
         setTimeout(()=>{
           navigate('/auth/login')
           localStorage.clear()
         }, 2000);
       }else{
         notify(errData?.message || 'Something went wrong.', 'error')
       }
       
     },
   })
 }

 useEffect(() => {
  if(params.id){
    getAppById()
  }
 }, [params.id])

 useEffect(() => {
  if(sessionStorage.getItem('category_1')===null){
    navigate(`/designer/${params.id}/product`)
  }
 }, [sessionStorage])

  return (
    <div className='flex flex-col bg-black h-full w-full py-4 px-6 md:px-32 md:py-12 text-white'>

      <span className="text-[40px] md:text-[42px] lg:text-[48px] hidden md:flex">{appName}</span>

      <div className="bg-black md:bg-[#292A2C] md:mt-5 h-full flex flex-col md:px-14 md:py-6 rounded-lg w-full overflow-y-auto hidescrl">
      <div className="w-full flex justify-between items-start">
            <i 
              className="ri-arrow-left-line text-[24px] pb-5 md:pb-2" 
              onClick={()=>{
                navigate(-1)
              }}
            />

            <span className="text-[24px] md:hidden flex">{appName}</span>
          </div>

      <div className="text-[32px] leading-tight captions-medium">
          {capitalizeFirstLetter(categoryData?.name)}
      </div>

      <div className="text-[14px] leading-tight captions-font pt-1 font-light">
        {categoryData?.subHeading}
      </div>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 col-span-2 md:col-span-3 lg:col-span-4 gap-x-2 gap-y-7 md:gap-y-4 mt-10 overflow-scroll hidescrl">
          {categoryData?.data?.map((item: any, index) => 
            <div className="flex flex-col justify-center items-center w-full "
            key={index} 
             onClick={()=>{
              sessionStorage.setItem('category_4', item )
              navigate(`/designer/${params.id}/designs`)
            }}>
              <img src={preview1} alt='preview1' className='w-[120px] h-[120px] rounded-full bg-gray-200'/>
              <div className="flex pt-2 justify-center items-center w-full text-center captions-font">{capitalizeFirstLetter(item)}</div>
            </div>
          )}
      </div>
        
      </div>

    
        
    </div>
  )
  
}

export default Category4Page
