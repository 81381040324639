import { useQuery } from "react-query";
import { get } from "framework/api/http";

/* Get all apps */
export const getAllApps = async () => {
    const res = await get(`/app-api/get_all_apps`)
    return res?.data || []
  }

/* Use Query as a Hook */
export const useGetAllApps = (onSuccess: any, onError: any ) => {
    return useQuery('all-apps', getAllApps, {
      refetchOnWindowFocus: false,
      enabled: false,
      retry:0,
      onSuccess,
      onError,
    })
  }

export default useGetAllApps;


