import { Button, Input } from "components/atoms"
import account from 'assets/marketPlace/account.svg'
import wallet from 'assets/marketPlace/wallet.svg'
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { ProfileDrawer, WalletDrawer } from "components/molecules/Drawers/MarketPlace"

export const HomeLayout= ({children}: any) => {

  const navigate = useNavigate()

  
  const token = localStorage.getItem('token')

  const [isOpenProfileDrawer, setIsOpenProfileDrawer] = useState(false)
  const [isOpenWalletDrawer, setIsOpenWalletDrawer] = useState(false)
 
  return (
    <div className="flex flex-col bg-black h-full w-full py-4 px-6 md:px-14 md:py-12 text-white">     
      <div className="flex justify-between items-center">
        <span className="hidden md:flex text-5xl font-medium pr-4">MarketplaceAI</span>

        <div className="flex items-center justify-between md:justify-end w-full">
          <Input labelValue="" value={null} placeholder="Search apps & services" onChange={(e)=>{
                //
          }} 
          className="w-full md:w-[280px]"
          inputClassName="!rounded-[30px] h-[45px]"
          icon={'ri-search-line'}
          />
          
           {/* header icons - mobile only */}
           {token && 
            <div className="md:hidden flex pr-5">
              <img src={wallet} 
                alt='wallet' 
                className='cursor-pointer w-[26px] h-[26px] object-contain ml-[18px]'
                onClick={()=>{navigate('/my-wallet')}}
              />
              <img src={account} 
                alt='account' 
                className='cursor-pointer w-[26px] h-[26px] object-contain ml-[20px]'
                onClick={()=>{navigate('/my-profile')}}
              />
            </div>
          }

          {/* header icons - tab and desktop only */}
          {token && 
          <div className="hidden md:flex">
            <img src={wallet} 
              alt='wallet' 
              className='cursor-pointer w-[26px] h-[26px] object-contain ml-[18px]'
              onClick={()=>{setIsOpenWalletDrawer(true)}}
            />
            <img src={account} 
              alt='account' 
              className='cursor-pointer w-[26px] h-[26px] object-contain ml-[20px]' 
              onClick={()=>{setIsOpenProfileDrawer(true)}}
            />
          </div>}
          
          {!token && 
          <div>
            <Button
              appearance="market-red" 
              className='w-[100px] ml-2' 
              onClick={()=>{
                navigate('/auth/login')
              }}
            >
              Login
            </Button>
          </div>
          }
        </div>
      </div>

      {children && children}
     
     <WalletDrawer isOpen={isOpenWalletDrawer} setIsOpen={setIsOpenWalletDrawer} />

     <ProfileDrawer isOpen={isOpenProfileDrawer} setIsOpen={setIsOpenProfileDrawer} />
    </div>
  )
  
}

export default HomeLayout
