import { FC } from 'react'
import CN from 'classnames'
import { Loader } from '../Loader'

export interface ButtonProps {
  className?: string | undefined
  disabled?: boolean | undefined
  isLoading?: boolean | undefined
  isCustomStyles?: boolean | undefined
  appearance?: 'market-red' | 'market-outline' | 'link' | undefined //when new apps implement , add new appearance type and define in the button class
  onClick?: (e: any) => void | undefined
  isBlock?: boolean
  [x: string]: any
}

export const Button: FC<ButtonProps> = ({
  children,
  disabled,
  className,
  appearance,
  isLoading,
  isBlock,
  onClick,
}: ButtonProps) => {
  const ButtonClasses = CN(
    'button h-fit font-medium rounded-[30px] px-[30px] py-[14px] text-[14px] leading-[16px] text-white captions-font',
    className,
    {
      'bg-[#FF204E] disabled:bg-[#FF204E] disabled:bg-opacity-70':
        appearance === 'market-red' || !appearance,
      'border border-1 border-[#FF204E] disabled:border-[#FF204E]':
        appearance === 'market-outline',
        'underline':
            appearance === 'link',
    },
    {
      'cursor-not-allowed !opacity-70': disabled,
      'cursor-pointer': !disabled,
    },
    {
      'w-full': isBlock,
      'w-fit': !isBlock,
    },
  )

  return (
    <button
      onClick={onClick}
      disabled={disabled || isLoading}
      className={ButtonClasses}>
      <div className='relative w-full'>
        <div
          className={CN({
            'opacity-0 flex items-center justify-center font-medium': isLoading,
            'opacity-100 flex items-center justify-center': !isLoading,
          })}>
          {children}
        </div>
        {isLoading && (
          <div className='absolute translate-x-[-50%] translate-y-[-150%] left-[50%] '>
            <Loader />
          </div>
        )}
      </div>
    </button>
  )
}

Button.defaultProps = {
  className: undefined,
  onClick: undefined,
  isLoading: false,
  isBlock: false,
  appearance: undefined,
}

export default Button
