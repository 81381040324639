import React from 'react';
import classNames from 'classnames';

interface ProgressBarProps {
  total: number;
  count: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ total, count }) => {
  const fillPercentage = Math.min((count / total) * 100, 100);

  return (
    <div className="w-full bg-neutral-200 rounded-full h-4">
      <div
        className={classNames('h-4 rounded-full', {
          'bg-[#FF859E]': fillPercentage > 0,
        })}
        style={{ width: `${fillPercentage}%` }}
      />
    </div>
  );
};

export default ProgressBar;