import React, { useEffect, useState } from 'react';
import CN from 'classnames'

type RatingProps = {
  totalStars?: number;
  onRatingChange?: (rating: number) => void;
  className?: string;
  selectedRating: any;
  theme?: 'red' | 'yellow'
  iconClassName?: string;
  isEditable?: boolean;
  defaultValue?: any
};

export const Rating: React.FC<RatingProps> = ({ totalStars = 5, onRatingChange,className , selectedRating, theme='yellow',iconClassName, isEditable = true, defaultValue}) => {
  const [rating, setRating] = useState<number>(selectedRating || 0);

  const handleStarClick = (index: number) => {
    const newRating = index + 1;
    setRating(newRating);
    if (onRatingChange) {
      onRatingChange(newRating);
    }
  };

  useEffect(() => {
    defaultValue && setRating(Math?.floor(defaultValue))
  }, [defaultValue])

  return (
    <div className={CN("flex items-center justify-between" ,className)}>
      {Array.from({ length: totalStars }, (_, index) => (
        <i
          key={index}
          className={CN('text-3xl cursor-pointer transition-colors duration-200', iconClassName,{
            'ri-star-fill text-yellow-500': index < rating && theme==='yellow',
            'ri-star-fill text-[#FF204E]': index < rating && theme==='red',
            'ri-star-line text-gray-500': index >= rating
          })}
          onClick={() => isEditable && handleStarClick(index)}
        ></i>
      ))}
    </div>
  );
};

export default Rating;