import { Slider } from "components/molecules";
import dialogLogo from 'assets/marketPlace/dialogLogo.png'
import { Button, Input } from "components/atoms";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useCheckUser, useVerifyOTP, useVerifyOTPWithRegister } from "framework/api/methods";
import { toast } from "react-toastify";


export const OTPVerifyPage= () => {
  const navigate = useNavigate()
  const location = useLocation()

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const [otp, setOtp] = useState('')

  const { mutate: verifyOTPWithRegisterMutate, isLoading: verifyOTPWithRegisterIsLoading } = useVerifyOTPWithRegister()
  const { mutate: verifyOTPMutate, isLoading: verifyOTPIsLoading } = useVerifyOTP()
  const { mutate: resendOTPMutate, isLoading: resendOTPIsLoading } = useCheckUser()

  // request OTP for un registered users
  const handleRequestOTPWithRegister = () => {
    verifyOTPWithRegisterMutate({
      data: otp,
      serverRef: location.state?.ref,
      name: location.state?.name,
      email: location.state?.email
    }, {
      onSuccess: ({ data: successData }: any) => {
        localStorage.setItem('token', successData?.token)
        notify(successData?.message || 'User Verified!', 'success')
        navigate('/auth/complete-profile')
      },
      onError: ({ response: { data: errData } }: any) => {
        if (errData?.message) {
          notify(errData?.message || 'Something went wrong.', 'error')
        }
      },
    })
  }

  //resend otp
  const resendOTP = () => {
    resendOTPMutate({
      mobile: location.state?.phoneNumber
    }, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData?.message || 'OTP has been sent successfully', 'success')
      
      },
      onError: ({ response: { data: errData } }: any) => {
        if (errData?.message) {
        notify(errData?.message || 'Something went wrong.', 'error')
        }
      },
    })
  }

  // request OTP for registered users
  const handleRequestOTP = () => {
    verifyOTPMutate({
      data: otp,
      serverRef: location.state?.ref
    }, {
      onSuccess: ({ data: successData }: any) => {
        localStorage.setItem('token', successData?.token)
        notify(successData?.message || 'User Verified!', 'success')
        navigate(localStorage.getItem('recent-path') || '/')
      },
      onError: ({ response: { data: errData } }: any) => {
        if (errData?.message) {
          notify(errData?.message || 'Something went wrong.', 'error')
        }
      },
    })
  }

  // handle input change
  const handleChange = (e:any) => {
    const inputValue = e.target.value
    if (/^\d*$/.test(inputValue)) {
      setOtp(e.target.value)
    }
  }

  return (
    <div className='flex flex-1 h-full min-h-full w-full bg-black text-white px-6 py-10'>
      {/* slider */}
      <div className="hidden md:flex w-1/2 h-full flex-col justify-between md:justify-center items-center pb-0 md:pb-10">
        <div className="flex flex-col w-full justify-center items-center">
          <Slider selectedIndex={1}/>
          <img src={dialogLogo} alt='dialogLogo' className='lg:w-[120px] md:[100px] w-[93px] h-fit mt-[18px] md:mt-[30px] lg:mt-[40px]'/>
          <span className="text-[40px] md:text-[42px] lg:text-[48px] mt-[10px]">MarketplaceAI</span>

          <Button appearance="market-red" className="mt-9 flex md:hidden"
            onClick={()=>{
              navigate('/auth/login')
            }}
          >Continue with phone number</Button>
        </div>
        
        <Button appearance="link" className="mt-0" 
          onClick={()=>{
            //
          }}
        >T&C | Privacy Notice</Button>
      </div>

      {/* login form */}
      <div className="w-full md:w-1/2 flex-col flex justify-center items-center captions-font">
        <div className="flex flex-col h-full justify-start md:justify-center md:w-fit">
          <i className="ri-arrow-left-line text-[24px] pb-10 md:hidden" onClick={()=>{
              navigate(-1)
            }}/>
          <span className="text-[32px]">OTP</span>

          <span className="text-[14px] pt-[1px]">Enter the OTP you received via SMS.</span>

          <Input 
            labelValue="OTP"
            value={otp} 
            onChange={handleChange}
            className="mt-[120px] md:mt-[60px] w-[320px]"
          />

          <div className="flex flex-col justify-center items-center">
            <Button 
              appearance="market-red"
              className="mt-[100px]"
              isBlock
              disabled={!otp}
              isLoading={verifyOTPIsLoading || verifyOTPWithRegisterIsLoading}
              onClick={() => {
                location.state?.isNewUser ? handleRequestOTPWithRegister() : handleRequestOTP()
              }}
            >Get Started</Button>

            <Button 
              appearance="link" 
              className="mt-6" 
              isLoading={resendOTPIsLoading} 
              onClick={() => {
                resendOTP()
              }}
            >Resend OTP</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPVerifyPage
