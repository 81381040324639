import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface AppViewByIdIdInterface {
  appId: string
}

export const useAppViewById = () => {
  const mutate = useMutation(({appId}: AppViewByIdIdInterface) => {
    return get(`/app-api/add_views/${appId}`)
  })

  return mutate
}

export default useAppViewById
