import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface AddDownloadsInterface {
  appId: string
  imageURL: string
  type: string
}

export const useAddDownloads = () => {
  const mutate = useMutation(({ appId, imageURL, type }: AddDownloadsInterface) => {
    return postAPI('/downloads-api/add_downloads', {
      appId,
      imageURL,
      type,
    })
  })

  return mutate
}

export default useAddDownloads


