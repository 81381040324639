import React, { FC, ReactNode } from 'react'
import CN from 'classnames'
import { motion } from 'framer-motion'

import { Button } from '../Button'

interface DrawerProps {
  children?: ReactNode | string | number | undefined
  className?: string | undefined
  footerLeftButtonIcon?: ReactNode | string | undefined
  footerLeftButtonTitle?: string
  footerLeftButton?: ReactNode
  headerSubtitle?: string
  headerTitle?: string
  isActive?: boolean | undefined
  isCustomFooterLeftButton?: boolean
  isCustomStyles?: boolean
  isFooterShow?: boolean
  isHeaderShow?: boolean
  isPrimaryButtonDisable?: boolean
  onFooterSubmitButtonClick?: () => void | undefined
  onFooterCancelButtonClick?: () => void | undefined
  onFooterLeftButtonClick?: () => void | undefined
  onHeaderCloseButtonClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  primaryButtonTitle?: string
  secondaryButtonTitle?: string
  width?: string | number | undefined
  modalContainerClass?: string | undefined
  [x: string]: any
}

export const Drawer: FC<DrawerProps> = ({
  children,
  className,
  footerLeftButtonIcon,
  footerLeftButtonTitle,
  footerLeftButton,
  headerSubtitle,
  headerTitle,
  isActive,
  isCustomFooterLeftButton,
  isCustomStyles,
  isFooterShow,
  isHeaderShow,
  isPrimaryButtonDisable,
  onFooterSubmitButtonClick,
  onFooterCancelButtonClick,
  onFooterLeftButtonClick,
  onHeaderCloseButtonClick,
  onOverlayClick,
  primaryButtonTitle,
  secondaryButtonTitle,
  width,
  modalContainerClass,
  ...restProps
}: DrawerProps) => {
  /** Overlay styles */
  const OverlayClassName = !isCustomStyles
    ? CN(
        'modal-overlay fixed top-0 bottom-0 left-0 right-0 z-50 flex justify-end bg-black bg-opacity-50',
        className,
        {
          hidden: !isActive,
        },
      )
    : className

  /** main modal container styles */
  const ModalContainerClassName = CN(
    'modal-container flex flex-col justify-start items-center bg-black shadow-lg shadow-gray-300 rounded-sm h-full',modalContainerClass
  )

  return (
    <div
      className={OverlayClassName}
      onClick={(e: any) => {
        e.stopPropagation()
        e.preventDefault()
        onOverlayClick && onOverlayClick()
      }}
      {...restProps.overlayProps}>
      {/* Modal main container  */}

      <motion.div
        animate={isActive ? 'open' : 'closed'}
        variants={{
          open: { opacity: 1, x: '0', transition: { duration: 0.1 } },
          closed: { opacity: 1, x: '100%' },
        }}
        className={ModalContainerClassName}
        style={{ width: width }}
        {...restProps.modalProps}
        onClick={(e: any) => e.stopPropagation()}>
        {isHeaderShow && (
          /* Modal Header  */
          <div
            className='flex w-full justify-between border-b-[1px] border-neutral-800 py-5 px-6'
            {...restProps.headerProps}>
            <div className='flex flex-col items-start'>
              <span className='header-title text-white line-clamp-1 text-[32px] font-medium'>
                {headerTitle}
              </span>
              
              {headerSubtitle && 
              <span className='header-subtitle text-[14px] leading-[15px] font-normal text-white captions-font'>
                {headerSubtitle}
              </span>}
            </div>

            <div className='flex items-center justify-center header-close-button'>
              <i className='ri-close-fill w-3 flex justify-center items-center cursor-pointer'
              {...restProps.headerCloseButtonProps}
              onClick={() => {
                onHeaderCloseButtonClick && onHeaderCloseButtonClick()
              }}></i>
            </div>
          </div>
        )}

        {/* Modal body */}
        <div className='flex w-full h-full overflow-y-auto styled-scroll'>
          {children}
        </div>

        {/* Modal footer */}
        {isFooterShow && (
          <>
            <div
              //className='flex justify-end items-center w-full border-t-[1px] border-Gray-200 py-5 pr-6 gap-3'
              className={CN(
                'flex justify-between items-center w-full border-t-[1px] border-N-200 py-5 pr-6 gap-3',
                {
                  'justify-between': isCustomFooterLeftButton,
                  'justify-end': !isCustomFooterLeftButton,
                },
              )}
              {...restProps.footerProps}>
              {isCustomFooterLeftButton && (
                <div className='pl-6'>
                  <Button
                    className='mr-2'
                    appearance='market-outline'
                    onClick={() => {
                      onFooterLeftButtonClick && onFooterLeftButtonClick()
                    }}
                    {...restProps.primaryButtonProps}>
                    {primaryButtonTitle}
                  </Button>
                </div>
              )}
              <div className='pl-6'>{footerLeftButton}</div>
              <div className='flex gap-3'>
                {secondaryButtonTitle !== '' && (
                  /*footer primary button*/    
                  <Button
                    appearance='market-outline' 
                    onClick={() => {
                      onFooterCancelButtonClick && onFooterCancelButtonClick()
                    }}
                    {...restProps.secondaryButtonProps}>
                    {secondaryButtonTitle}
                  </Button>
                )}

                {primaryButtonTitle !== '' && (
                  /*footer secondary button*/

                  <Button
                    appearance='market-red'
                    onClick={() => {
                      onFooterSubmitButtonClick && onFooterSubmitButtonClick()
                    }}
                    disabled={isPrimaryButtonDisable}
                    >
                    {primaryButtonTitle}
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
      </motion.div>
    </div>
  )
}

export default Drawer

Drawer.defaultProps = {
  children: undefined,
  className: undefined,
  footerLeftButtonIcon: undefined,
  footerLeftButtonTitle: 'Delete',
  footerLeftButton: undefined,
  headerSubtitle: undefined,
  headerTitle: undefined,
  isActive: true,
  isCustomFooterLeftButton: false,
  isCustomStyles: false,
  isFooterShow: true,
  isHeaderShow: true,
  isPrimaryButtonDisable: true,
  onFooterSubmitButtonClick: undefined,
  onFooterCancelButtonClick: undefined,
  onFooterLeftButtonClick: undefined,
  onHeaderCloseButtonClick: undefined,
  onOverlayClick: undefined,
  primaryButtonTitle: 'Submit',
  secondaryButtonTitle: 'Cancel',
  width: undefined,
  modalContainerClass:undefined
}
