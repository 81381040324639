import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";
import { useGetAppById, useGetSamplePreview } from "framework/api/methods";
import { useEffect, useState } from "react";
import capitalizeAll from "utils/capitalizeAll";
import AbstractStyle from 'assets/merchandise/Styles/AbstractStyle.jpeg'
import AnimeStyle from 'assets/merchandise/Styles/AnimeStyle.jpeg'
import ClaymationStyle from 'assets/merchandise/Styles/ClaymationStyle.jpeg'
import CubistStyle from 'assets/merchandise/Styles/CubistStyle.jpeg'
import FlatIllustrationStyle from 'assets/merchandise/Styles/FlatIllustrationStyle.jpeg'
import FuturisticStyle from 'assets/merchandise/Styles/FuturisticStyle.jpeg'
import GeometricStyle from 'assets/merchandise/Styles/GeometricStyle.jpeg'
import HandDrawnStyle from 'assets/merchandise/Styles/HandDrawnStyle.jpeg'
import MinimalisticStyle from 'assets/merchandise/Styles/MinimalisticStyle.jpeg'
import Other from 'assets/merchandise/Styles/Other.jpeg'
import PixelArtStyle from 'assets/merchandise/Styles/PixelArtStyle.jpeg'
import PolyStyle from 'assets/merchandise/Styles/PolyStyle.jpeg'
import PopArtStyle from 'assets/merchandise/Styles/PopArtStyle.jpeg'
import VintageStyle from 'assets/merchandise/Styles/VintageStyle.jpeg'
import WaterColorStyle from 'assets/merchandise/Styles/WaterColorStyle.jpeg'
import { Button, Loader } from "components/atoms";
import CN from 'classnames'

export const Category3Page= () => {
  const navigate = useNavigate()
 const params = useParams()

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const [categoryData, setCategoryData] = useState({
    name: '',
    subHeading: '',
    data: []
  })
  const [isNextCategoryAvailable, setIsNextCategoryAvailable] = useState(true)
  
 const { mutate: getAppByIdMutate, isLoading: getAppByIdIsLoading, } = useGetAppById()

 const [selectedItem, setSelectedItem] = useState<string | null>('')
 const [appName, setAppName] = useState(sessionStorage.getItem('app-name'))

 const getAppById = () => {
  getAppByIdMutate({
    appId: params.id?.toString() || ''
   }, {
     onSuccess: ({ data: successData }: any) => {
    
      const data = JSON.parse(successData?.data[0]?.category_3)
      setAppName(successData?.data[0]?.app_name)
      sessionStorage.setItem('app-name',successData?.data[0]?.app_name || '')
      setCategoryData({
        ...categoryData,
        name: data[0]?.name,
        subHeading: '',
        data: data[0]?.data
      })
      setIsNextCategoryAvailable(successData?.data[0]?.category_4=== "[]"? false : true)
     },
     onError: ({ response: { data: errData }, response }: any) => {
       if(response?.status===405 || response?.status===403 || response?.status===501 ){
        if(response?.status===403 ){
          notify('Please login to continue.', 'error')
        }else{
          notify('Session expired.', 'error')
        }
         setTimeout(()=>{
           navigate('/auth/login')
           localStorage.clear()
         }, 2000);
       }else{
         notify(errData?.message || 'Something went wrong.', 'error')
       }
       
     },
   })
 }

 useEffect(() => {
  if(params.id){
    getAppById()
  }
 }, [params.id])

 useEffect(() => {
  if(sessionStorage.getItem('category_1')===null){
    navigate(`/designer/${params.id}/product`)
  }
 }, [sessionStorage])

 
 const renderImage=(name: string)=>{
  switch(name){
    case 'ABSTRACT STYLE': return AbstractStyle ;
    case 'ANIME STYLE': return AnimeStyle ;
    case 'CLAYMATION STYLE': return ClaymationStyle ;
    case 'CUBIST STYLE': return CubistStyle ;
    case 'FLAT ILLUSTRATION STYLE': return FlatIllustrationStyle ;
    case 'FUTURISTIC STYLE': return FuturisticStyle ;
    case 'GEOMETRIC STYLE': return GeometricStyle ;
    case 'HAND DRAWN STYLE': return HandDrawnStyle ;
    case 'MINIMALISTIC STYLE': return MinimalisticStyle ;
    case 'PIXEL ART STYLE': return PixelArtStyle ;
    case 'POLY STYLE': return PolyStyle ;
    case 'POP ART STYLE': return PopArtStyle ;
    case 'VINTAGE STYLE': return VintageStyle ;
    case 'WATERCOLOR STYLE': return WaterColorStyle ;
    default: return Other
  }
 }

 
 const { mutate: getSamplePreviewMutate, isLoading:getSamplePreviewIsLoading } = useGetSamplePreview()

 // get sample preview for app
 const getSamplePreview = (style: string) => {
  sessionStorage.setItem('category_3', style)
  getSamplePreviewMutate({
    appId: params.id || '',
    category_1: sessionStorage.getItem('category_1'),
    category_2: sessionStorage.getItem('category_2'),
    category_3: style,
    category_4: sessionStorage.getItem('category_4') || "null"
   }, {
     onSuccess: ({ data: successData }: any) => {
      successData.data?.length>0 ? setSelectedItem(successData.data[0].coverImage || '') : setSelectedItem(null)
     },
     onError: ({ response: { data: errData }, response }: any) => {
       if(response?.status===405 || response?.status===403 || response?.status===501 ){
        if(response?.status===403 ){
          notify('Please login to continue.', 'error')
        }else{
          notify('Session expired.', 'error')
        }
         setTimeout(()=>{
           navigate('/auth/login')
           localStorage.clear()
         }, 2000);
       }else{
         notify(errData?.message || 'Something went wrong.', 'error')
       }
     },
   })
 }

  return (
    <div className='flex flex-col bg-black h-full w-full py-4 px-6 md:px-32 md:py-12 text-white'>

      <span className="text-[40px] md:text-[42px] lg:text-[48px] hidden md:flex">{appName}</span>

      <div className="bg-black md:bg-[#292A2C] md:mt-5 h-full flex flex-col md:px-14 md:py-6 rounded-lg w-full md:overflow-y-auto md:hidescrl overflow-hidden">
          <div className="w-full flex justify-between items-start">
            <i 
              className="ri-arrow-left-line text-[24px] pb-5 md:pb-2" 
              onClick={()=>{
                navigate(-1)
              }}
            />

            <span className="text-[24px] md:hidden flex">{appName}</span>
          </div>

      <div className="text-[32px] leading-tight captions-medium">
        Style
      </div>

      <div className="text-[14px] leading-tight captions-font pt-1 font-light">
        Pick a style as you prefer
      </div>

      {/* mobile only */}
      <div className="md:hidden flex flex-col md:flex-row gap-x-16 gap-y-4 md:h-[70%] h-full justify-start items-start mt-10">
        <div className="flex flex-col justify-center items-center md:w-1/3 h-fit md:h-full w-full">
          <div className="mb-4 text-base font-normal captions-font">Sample preview for your selections</div>
          {selectedItem ? 
            <img src={selectedItem || undefined} alt='preview1' className='relative w-[211px] h-[211px] md:w-[250px] md:h-[250px] rounded bg-gray-200'/>
            : 
            <div  className='relative w-[211px] h-[211px] md:w-[300px] md:h-[300px] captions-font flex justify-center items-center font-normal text-[10px] text-center bg-slate-400/20 rounded'>
              Select style for get the preview.
            </div>
          }

          <div  className='absolute w-[211px] h-[211px] md:w-[300px] md:h-[300px] captions-font flex justify-center items-center font-normal text-[10px] text-center'>
            {getSamplePreviewIsLoading && <Loader  className="mt-5"/>}
          </div>

          <Button appearance="market-red" className='mt-[10px] w-[80%]'
            disabled={selectedItem == null || selectedItem === undefined || selectedItem === '' || getSamplePreviewIsLoading}
              onClick={()=>{
                if(isNextCategoryAvailable){
                  navigate(`/designer/${params.id}/c-4`)
                }else{
                  navigate(`/designer/${params.id}/designs`)
                }
            }}>
               Continue
            </Button>
        </div>

        <div className={CN("md:hidden grid grid-cols-2 md:grid-cols-2 col-span-2 md:col-span-2 gap-x-2 gap-y-7 md:gap-y-4 overflow-auto hidescrl md:w-2/3 w-full bg-[#555050] rounded-[18px] p-5 md:h-auto h-fit pb-[180px]",{
          'grid-cols-1': getAppByIdIsLoading
        })}>
          {getAppByIdIsLoading && <Loader  className="mt-10"/>}

          {categoryData?.data?.map((item: any, index) => 
            <div className="flex flex-col justify-center items-center w-full h-fit cursor-pointer"
              key={index} 
              onClick={()=>{
                getSamplePreview(item);
              }}>
              <img src={renderImage(capitalizeAll(item))} alt='preview1' className='w-[120px] h-[120px] rounded-full bg-gray-200'/>
              <div className="flex pt-2 justify-center items-center w-full text-center captions-font">{capitalizeFirstLetter(item)}</div>
            </div>
          )}
          
        </div>
      </div>


      {/* tab and desktop */}
      <div className="hidden md:flex flex-col md:flex-row gap-x-16 gap-y-4 md:h-[70%] h-full mt-10">
        <div className={CN("grid grid-cols-2 md:grid-cols-2 col-span-2 md:col-span-2 gap-x-2 gap-y-7 md:gap-y-4 overflow-auto hidescrl md:w-2/3 w-full bg-[#555050] rounded-[18px] p-5 md:h-auto h-[300px]",{
              'md:grid-cols-1 lg:grid-cols-1': getAppByIdIsLoading
        })}>
          {getAppByIdIsLoading && <Loader  className="mt-10"/>}

          {categoryData?.data?.map((item: any, index) => 
            <div className="flex flex-col justify-center items-center w-full h-fit cursor-pointer"
              key={index} 
              onClick={()=>{
                getSamplePreview(item);
              }}>
              <img src={renderImage(capitalizeAll(item))} alt='preview1' className='w-[120px] h-[120px] rounded-full bg-gray-200'/>
              <div className="flex pt-2 justify-center items-center w-full text-center captions-font">{capitalizeFirstLetter(item)}</div>
            </div>
          )}
        </div>

        <div className="flex flex-col justify-center items-center md:w-1/3 h-fit md:h-full w-full">
          <div className="mb-4 text-base font-normal captions-font">Sample preview for your selections</div>
          
          {selectedItem ? 
            <img src={selectedItem || undefined} alt='preview1' className='relative w-[211px] h-[211px] md:w-[250px] md:h-[250px] rounded bg-gray-200'/>
            : 
            <div  className='relative w-[211px] h-[211px] md:w-[300px] md:h-[300px] captions-font flex justify-center items-center font-normal text-[10px] text-center bg-slate-400/20 rounded'>
              Select style for get the preview.
            </div>
          }

          <div  className='absolute w-[211px] h-[211px] md:w-[300px] md:h-[300px] captions-font flex justify-center items-center font-normal text-[10px] text-center'>
            {getSamplePreviewIsLoading && <Loader  className="mt-5"/>}
          </div>

          <Button appearance="market-red" className='mt-[10px] w-[80%]'
            disabled={selectedItem == null || selectedItem === undefined || selectedItem === ''|| getSamplePreviewIsLoading}
              onClick={()=>{
                if(isNextCategoryAvailable){
                  navigate(`/designer/${params.id}/c-4`)
                }else{
                  navigate(`/designer/${params.id}/designs`)
                }
            }}>
               Continue
            </Button>
        </div>
      </div>
      </div>    
    </div>
  )
  
}

export default Category3Page
