import { useLocation, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import {  useEffect, useState } from "react";
import { Button } from "components/atoms";

import SuccessTick from 'assets/merchandise/SuccessTick.svg'
import dateToReadableString from "utils/dateToReadableString";
import { useGetAppById } from "framework/api/methods";

export const RequestSentPage= () => {
  const navigate = useNavigate()
 const params = useParams()
 const location = useLocation()

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

 useEffect(() => {
  if(sessionStorage.getItem('category_1')===null || location?.state?.qid===null ||location?.state?.qid===''){
    navigate(`/designer/${params.id}/product`)
  }
 }, [sessionStorage])
 

//  const onSubmitMobile = () => {
//     let url = `https://wa.me/+${location?.state?.supplierContact}`;
//     url += `?text=${encodeURI(`Hello ${location?.state?.supplier_name},\n\nYou have received a new quotation request from a customer.\n\nQuotation Number: ${location?.state?.qid}\nRequired Date: ${dateToReadableString(location?.state?.requiredDate)}\nQuantity: ${location?.state?.qty}\nInstructions: ${location?.state?.instruction}\n\nYou can also view the sample design using the following link: ${window?.location?.origin}/quotation/${location?.state?.qid}/\n\nPlease review the details and contact the customer.\nName:  ${location?.state?.user_name}\nContact Number: +${location?.state?.user_contact}\nEmail:  ${location?.state?.user_email}`)}&app_absent=0`;
//     window.open(url);
//   };

//   const onSubmitPC = () => {
//     let url = `https://web.whatsapp.com/send?phone=+${location?.state?.supplierContact}`;
//     url += `&text=${encodeURI(`Hello ${location?.state?.supplier_name},\n\nYou have received a new quotation request from a customer.\n\nQuotation Number: ${location?.state?.qid}\nRequired Date: ${dateToReadableString(location?.state?.requiredDate)}\nQuantity: ${location?.state?.qty}\nInstructions: ${location?.state?.instruction}\n\nYou can also view the sample design using the following link: ${window?.location?.origin}/quotation/${location?.state?.qid}/\n\nPlease review the details and contact the customer.\nName:  ${location?.state?.user_name}\nContact Number: +${location?.state?.user_contact}\nEmail:  ${location?.state?.user_email}`)}&app_absent=0`;
//     window.open(url);
//   };

  
const [appName, setAppName] = useState(sessionStorage.getItem('app-name'))

const { mutate: getAppByIdMutate, isLoading: getAppByIdIsLoading, } = useGetAppById()

const getAppById = () => {
 getAppByIdMutate({
   appId: params.id?.toString() || ''
  }, {
    onSuccess: ({ data: successData }: any) => {
     setAppName(successData?.data[0]?.app_name)
     sessionStorage.setItem('app-name',successData?.data[0]?.app_name || '')
    },
    onError: ({ response: { data: errData }, response }: any) => {
      if(response?.status===405 || response?.status===403 || response?.status===501 ){
       if(response?.status===403 ){
         notify('Please login to continue.', 'error')
       }else{
         notify('Session expired.', 'error')
       }
        setTimeout(()=>{
          navigate('/auth/login')
          localStorage.clear()
        }, 2000);
      }else{
        notify(errData?.message || 'Something went wrong.', 'error')
      }
      
    },
  })
}

useEffect(() => {
 if(params.id){
   getAppById()
 }
}, [params.id])


  return (
    <div className='flex flex-col bg-black h-full w-full py-4 px-6 md:px-32 md:py-12 text-white'>

      <span className="text-[40px] md:text-[42px] lg:text-[48px] hidden md:flex">{appName}</span>

      <div className="bg-black md:bg-[#292A2C] md:mt-5 h-full flex flex-col md:px-14 md:py-6 rounded-lg w-full overflow-y-auto hidescrl">
        <div className="w-full flex justify-between items-start">
          <i 
            className="ri-arrow-left-line text-[24px] pb-5 md:pb-2" 
            onClick={()=>{
              navigate(-1)
            }}
          />

          <span className="text-[24px] md:hidden flex">{appName}</span>
        </div>

        <div className="text-[32px] leading-tight captions-medium">
          Request sent
        </div>

        <div className="text-[14px] leading-tight captions-font pt-1 font-light">
          Vendor will get back to you with the requested quote
        </div>

        <div className="flex flex-col justify-center items-center overflow-scroll hidescrl mt-32 md:mt-10 gap-x-0 md:gap-x-10 gap-y-8 md:gap-y-0">
          
          <img src={SuccessTick} alt='success-tick' className='w-[171px] h-[171px] md:w-[260px] md:h-[260px]'/>

          {/* <Button
              appearance='market-outline'
              className="lg:hidden w-full md:w-[327px] mt-10"
              isBlock
              onClick={() => {
                onSubmitMobile()
              }}
              >
              Chat on WhatsApp
            </Button>

            <Button
              appearance='market-outline'
              className="hidden lg:flex w-full md:w-[327px] mt-10"
              isBlock
              onClick={() => {
                onSubmitPC()
              }}
              >
              Chat on WhatsApp
            </Button> */}
        </div>
      </div>  
    </div>
  )
  
}

export default RequestSentPage
