import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface RequestQuoteInterface {
  appId: string
  imageURL: string
  quantity: number
  required_date: Date 
  instructions: string
}

export const useRequestQuote = () => {
  const mutate = useMutation(({ appId, imageURL, quantity, instructions, required_date }: RequestQuoteInterface) => {
    return postAPI('/quotations-api/add_quotation', {
      appId, 
      imageURL, 
      quantity, 
      instructions,
      required_date
    })
  })

  return mutate
}

export default useRequestQuote


