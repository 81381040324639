import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface RequestOTPInterface {
  mobile: string
}

export const useRequestOTP = () => {
  const mutate = useMutation(({ mobile }: RequestOTPInterface) => {
    return postAPI('/user-api/request_otp', {
      mobile
    })
  })

  return mutate
}

export default useRequestOTP
