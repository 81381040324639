import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetLast4TemplatesInterface {
  appId: string
}

export const useGetLast4Templates = () => {
  const mutate = useMutation(({appId}: GetLast4TemplatesInterface) => {
    return get(`/template-api/getFourtemplates/${appId}`)
  })

  return mutate
}

export default useGetLast4Templates
