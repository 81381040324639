import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface UpdateUserProfileInterface {
  address: string
  dob: string
  gender: string
  language: string
}

export const useUpdateUserProfile = () => {
  const mutate = useMutation(({ address, dob, gender, language}: UpdateUserProfileInterface) => {
    return postAPI('/user-api/update_user_details', {
      address,
      dob,
      gender,
      language
    })
  })

  return mutate
}

export default useUpdateUserProfile
