import { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'

export interface MainLayoutProps {
  [x: string]: any
}

export const MainLayout: FC<MainLayoutProps> = ({
  children,
}: MainLayoutProps) => {

  const location = useLocation();

  useEffect(() => {
    localStorage.setItem('recent-path', location.pathname)
  },[location]);
  return (
    <div className='flex h-full w-full'>
      {children}

      <ToastContainer
        position='bottom-center'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
        className='toast-container'
      />
    </div>
  )
}

export default MainLayout
