/* eslint-disable jsx-a11y/no-autofocus */
import { FC } from 'react'
import CN from 'classnames'

export interface InputProps {
  className?: string | undefined
  inputClassName?: string
  isAutoFocus?: boolean
  isDisabled?: boolean
  isError?: boolean
  labelValue?: string | undefined
  labelClassName?: string
  onChange?: (e: any) => void | undefined
  onKeyPress?: (e: any) => void | undefined
  placeholder?: string
  type?: string
  value?: any
  isRequired?: boolean
  pattern?: string
  icon?: string
}

export const Input: FC<InputProps> = ({
  className,
  inputClassName,
  isAutoFocus,
  isDisabled,
  isError,
  labelValue,
  labelClassName,
  onChange,
  onKeyPress,
  placeholder,
  type,
  value,
  isRequired,
  pattern,
  icon
}: InputProps) => {
  return (
    <div className={CN('flex flex-col w-full captions-font', {}, className)}>
      {labelValue && (
        <span
          className={CN('text-sm font-normal',
            {
              'text-white pb-1': !isError,
              'text-red-500 pb-1': isError,
            },
            labelClassName
          )}>
          {labelValue}

          {isRequired && <span className='text-red-500 ml-[2px]'>*</span>}
        </span>
      )}
      <div className={CN({'flex justify-center items-center relative': icon !== undefined})}>
        <input
          placeholder={placeholder}
          type={type}
          autoFocus={isAutoFocus}
          value={value}
          onChange={onChange}
          onKeyPress={onKeyPress}
          className={CN(
            'w-full outline-none border border-[#676161] rounded py-[9px] px-4 text-base leading-[19px] bg-[#676161] bg-opacity-[40%] h-[56px] placeholder:text-[#CCCCCC]',
            { 'border-red-600': isError },
            {'!pl-10 !pr-4': icon !== undefined},
            inputClassName,
          )}
          disabled={isDisabled}
          pattern={pattern}
        />

        {icon &&
          <i className={`${icon} absolute text-[20px] pl-4 left-0 text-[#CCCCCC]`} />
        }
      </div>
    </div>
  )
}

Input.defaultProps = {
  className: undefined,
  inputClassName: undefined,
  isAutoFocus: false,
  isDisabled: false,
  isError: false,
  labelValue: undefined,
  labelClassName: undefined,
  onChange: undefined,
  onKeyPress: undefined,
  placeholder: undefined,
  type: 'text',
  value: undefined,
  isRequired: false,
  icon: undefined,
}

export default Input
