import { useQuery } from "react-query";
import { get } from "framework/api/http";

/* Get all apps */
export const getImageWithQid = async ({queryKey}: any) => {
    const requestBody = queryKey[1]
    const res = await get(`/quotations-api/getImageUrlwithQid/${requestBody.qId}`)
    return res?.data || []
  }

/* Use Query as a Hook */
export const useGetImageWithQid = ({...reqBody} , onSuccess: any, onError: any ) => {
    return useQuery(['get-image-by-qid',reqBody], getImageWithQid, {
      refetchOnWindowFocus: false,
      enabled: true,
      retry:0,
      onSuccess,
      onError,
    })
  }

export default useGetImageWithQid;



