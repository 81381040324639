import React, { FC, forwardRef } from 'react'
import CN from 'classnames'

interface RadioProps {
  className?: string | undefined
  id?: string | undefined
  isCustomStyles?: boolean
  isDisabled?: boolean
  defaultChecked?: boolean
  helperText?: string | undefined
  labelText?: string | undefined
  onRadioClick?: () => void
  onChange?: any | undefined
  name?: string | undefined
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      className,
      id,
      isCustomStyles,
      isDisabled,
      helperText,
      labelText,
      onRadioClick,
      defaultChecked,
      onChange,
      name,
      ...restProps
    }: RadioProps,
    ref,
  ) => {
    const MainClassName = !isCustomStyles
      ? CN('ParentClass flex', className)
      : className

    const ButtonClassName = CN(
      'form-check-input appearance-none rounded-full h-4 w-4 border border-neutral-400 bg-white checked:bg-white checked:border-[#ff6e8c] checked:border-[5px] hover:bg-neutral-300 active:bg-neutral-400 focus:outline-none focus:ring focus:ring-[#ffbdcb] transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer',
    )

    return (
      <label className={MainClassName} htmlFor={id}>
        <div className='left-side'>
          <input
            id={id}
            onChange={onChange}
            ref={ref}
            type='radio'
            onClick={onRadioClick}
            className={ButtonClassName}
            defaultChecked={defaultChecked}
            disabled={isDisabled}
            name={name}
            {...restProps}
          />
        </div>
        <div className='right-side flex flex-col justify-center pl-2'>
          {labelText && (
            <span className='font-normal text-white'>{labelText}</span>
          )}
          {helperText && (
            <span className='font-normal text-sm text-neutral-500'>
              {helperText}
            </span>
          )}
        </div>
      </label>
    )
  },
)

Radio.defaultProps = {
  className: undefined,
  id: undefined,
  isCustomStyles: undefined,
  isDisabled: false,
  helperText: undefined,
  labelText: undefined,
  onRadioClick: undefined,
  defaultChecked: undefined,
  onChange: undefined,
  name: undefined,
}

export default Radio
