import React, { useState, useRef, useEffect, FC } from 'react';
import preview1 from 'assets/marketPlace/preview-1.png'
import preview2 from 'assets/marketPlace/preview-2.png'
import preview3 from 'assets/marketPlace/preview-3.png'


export interface SliderProps {
  selectedIndex?: number
  [x: string]: any
}

export const Slider: FC<SliderProps> = ({
  selectedIndex = 0,
}: SliderProps) => {
  const [currentIndex, setCurrentIndex] = useState(selectedIndex | 0);
  const slideRef = useRef<HTMLDivElement>(null);

  const slides = [
    { id: 1, 
      content: 
      <div className='flex flex-col text-white text-center text-sm justify-center items-center'>
        <img src={preview1} alt='preview1' className='lg:w-[320px] md:[240px] w-[198px] h-fit'/>
        <span>T-shirt?</span>
        <span>Craft your one-of-a-kind design</span>
      </div>
    },
    { id: 2, 
      content: 
      <div className='flex flex-col text-white text-center text-sm justify-center items-center'>
        <img src={preview2} alt='preview2' className='lg:w-[317px] md:[240px] w-[240px] h-fit'/>
        <span>Fancy a custom cake?</span>
        <span>get it delivered in no time</span>
      </div>
    },
    { id: 3,
      content: 
      <div className='flex flex-col text-white text-center text-sm justify-center items-center'>
        <img src={preview3} alt='preview3' className='lg:w-[300px] md:[200px] w-[198px] h-fit'/>
        <span>Looking for a personalized</span>
        <span>flower bouquet?</span>
      </div>
     },
  ];
   
  const handleDotClick = (index: number) => {
    setCurrentIndex(index);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    const touchStartX = e.touches[0].clientX;
    slideRef.current!.dataset.touchStartX = touchStartX.toString();
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!slideRef.current!.dataset.touchStartX) return;
    const touchStartX = parseFloat(slideRef.current!.dataset.touchStartX);
    const touchEndX = e.touches[0].clientX;
    const deltaX = touchStartX - touchEndX;

    if (deltaX > 50) {
      setCurrentIndex((prev) => (prev + 1) % slides.length);
      slideRef.current!.dataset.touchStartX = '';
    } else if (deltaX < -50) {
      setCurrentIndex((prev) => (prev - 1 + slides.length) % slides.length);
      slideRef.current!.dataset.touchStartX = '';
    }
  };

  useEffect(() => {
    const handleResize = () => {
      slideRef.current!.style.transform = `translateX(-${currentIndex * 100}%)`;
    };
    handleResize();
  }, [currentIndex]);

  return (
    <div className="w-fit flex flex-col overflow-hidden items-center justify-center captions-font">
      <div
        className="flex transition-transform duration-300 ease-in-out items-end"
        ref={slideRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        {slides.map((slide) => (
          <div
            key={slide.id}
            className="w-full flex-shrink-0 flex items-center justify-center"
          >
            {slide.content}
          </div>
        ))}
      </div>
      
      <div className="flex space-x-[10px] text-center w-full justify-center items-center pt-[30px]">
        {slides.map((_, index) => (
          <button
            key={index}
            className={`w-2 h-2 rounded-full ${
              currentIndex === index ? 'bg-[#FF204E]' : 'bg-gray-400'
            }`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Slider