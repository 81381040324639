import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface GetAllTemplatesByAppIdInterface {
  appId: string
  category_1: string | null
  category_2: string | null
  category_3: string | null
  category_4: string | null
}

export const useGetAllTemplatesByAppId = () => {
  const mutate = useMutation(({appId, category_1, category_2, category_3, category_4}: GetAllTemplatesByAppIdInterface) => {
    return postAPI(`/template-api/getAlltemplates/${appId}`,{
      category_1,
      category_2,
      category_3,
      category_4
    })
  })

  return mutate
}

export default useGetAllTemplatesByAppId
