/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC } from "react";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import CN from "classnames";
import "./DateInputPicker.scss";

export interface DatePickerProps {
  allowEdit?: boolean;
  enabled?: boolean;
  format?: string | undefined;
  isErrored?: boolean;
  minDate?: any;
  onChange?: (e: any) => void | undefined;
  value?: any;
  width?: string | undefined;
  [x: string]: any;
}
export const DateInputPicker: FC<DatePickerProps> = ({
  allowEdit,
  enabled,
  format,
  isErrored,
  minDate,
  onChange,
  value,
  width,
  ...restProps
}: DatePickerProps) => {
  return (
    <div
      className={CN("date-picker w-[calc(100%-2px)] ml-[1px]", {
        "!outline-R-500 !outline-2": isErrored,
      })}
    >
      <DatePickerComponent
        id='datetimepicker'
        enabled={enabled}
        value={value}
        width={width}
        format={format}
        onChange={onChange}
        allowEdit={allowEdit}
        min={minDate}
        {...restProps}
      />
    </div>
  );
};
DateInputPicker.defaultProps = {
  allowEdit: true,
  enabled: true,
  format: "dd/MM/yyyy",
  minDate: undefined,
  onChange: undefined,
  value: undefined,
  width: undefined,
};
export default DateInputPicker;
