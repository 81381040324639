import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface GenerateAITemplateInterface {
  user_prompt: string
  date_time: Date
}

export const useGenerateAITemplate = () => {
  const mutate = useMutation(({ user_prompt, date_time }: GenerateAITemplateInterface) => {
    return postAPI('/order-api/make_order', {
      user_prompt, 
      date_time
    })
  })

  return mutate
}

export default useGenerateAITemplate


