import { useGetImageWithQid } from "framework/api/methods"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

export const QuoteImage= () => { 
  const params = useParams()
  const [imageSrc, setImageSrc] = useState<string|null>(null)
  const [isLoad, setIsLoad] = useState(true)

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const onSuccessGetImageWithQid =(data: any)=>{
    setImageSrc(data?.data[0].imageURL)
    downloadImage(data?.data[0].imageURL)
  }

  const onErrorGetImageWithQid =({ response: { data: errData } }: any)=>{
      notify(errData?.message || 'Something went wrong.', 'error')
  }

  /** get image for quotation */
  useGetImageWithQid({ qId : params.qid?.toString()},onSuccessGetImageWithQid, onErrorGetImageWithQid)

  
  const downloadImage = async (imageUrl: string) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    
    const a = document.createElement('a');
    a.href = url;
    a.download = `q_${params.qid?.toString()}.jpg`; // Set the file name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setIsLoad(false);
  };


  return (
    <div className='flex flex-col gap-y-2 justify-center items-center p-5'>
      {imageSrc && (
        <>
        {isLoad ? 
        `Your image is downloading. please wait...`
          :
        `Your image is downloaded.`
        }
        
        <img src={imageSrc} alt='img' className='w-full h-auto lg:w-auto lg:h-[80%]'/>
        </>
      )}
    </div>
  )
}
export default QuoteImage
