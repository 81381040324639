import { Drawer, SimpleSelect } from 'components/atoms'
import { Button, Input } from "components/atoms";
import { useNavigate } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { DateInputPicker } from "components/atoms";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useGetVerifyUserToken, useUpdateUserProfile } from 'framework/api/methods';
import { dateFormat } from 'utils';
import { setUserDetails } from 'store/reducers/user/userSlice';

export interface ProfileDrawerProps {
  isOpen: boolean
  setIsOpen: any
  [x: string]: any
}

export const ProfileDrawer: FC<ProfileDrawerProps> = ({
  isOpen,
  setIsOpen,
  ...restProps
}: ProfileDrawerProps) => {

  const navigate = useNavigate()

  const token = localStorage.getItem('token')
  
  const onSuccessVerifyToken =(data: any)=>{
    const userDetails = data?.userData
    setAddress(userDetails?.address || '')
    userDetails.gender && setGender({label:userDetails.gender, value:userDetails.gender})
    userDetails.language && setLanguage({label:userDetails.language, value:userDetails.language})
    setBirthDate(userDetails.dob || null)
  }

  const onErrorVerifyToken =()=>{
    localStorage.clear()
    navigate('/auth/login')
  }


  /** Get all cards API */
  const { refetch:getVerifyToken  } = useGetVerifyUserToken(onSuccessVerifyToken,onErrorVerifyToken, false)

  useEffect(() => {
    isOpen && token && getVerifyToken()
  }, [token, isOpen])




  const [address, setAddress] = useState('')
  const [birthDate, setBirthDate] = useState<any>()
  const [gender, setGender] = useState<any>(null)
  const [language, setLanguage] = useState<any>(null)

  
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  // useEffect(() => {
  //   if(userDetails){
  //     setAddress(userDetails?.address || '')
  //     userDetails.gender && setGender({label:userDetails.gender, value:userDetails.gender})
  //     userDetails.language && setLanguage({label:userDetails.language, value:userDetails.language})
  //     setBirthDate(userDetails.dob || null)
  //   }
    
  // }, [userDetails, isOpen])

  const { mutate: updateUserProfileMutate, isLoading: updateUserProfileIsLoading } = useUpdateUserProfile()

  // handle user profile updates
  const handleUpdateUserProfile = () => {
    updateUserProfileMutate({
      address: address || '',
      dob: birthDate || '',
      gender: gender?.value || '',
      language: language?.value || ''
    }, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData?.message || 'User details updated!', 'success')
        setIsOpen(false)
      },
      onError: ({ response: { data: errData }, response }: any) => {
        if(response?.status===405 || response?.status===403 || response?.status===501 ){
          if(response?.status===403 ){
            notify('Please login to continue.', 'error')
          }else{
            notify('Session expired.', 'error')
          }
          setTimeout(()=>{
            navigate('/auth/login')
            localStorage.clear()
          }, 2000);
        }else{
          notify(errData?.message || 'Something went wrong.', 'error')
        }
        
      },
    })
  }

  return (
    <Drawer
        isActive={isOpen}
        modalContainerClass='lg:w-[28%] md:w-[50%] w-[70%]'
        isFooterShow={false}
        isHeaderShow={false}
       >
        <div className='w-full px-12 py-6 flex-col flex justify-start items-center captions-font h-fit'>

          <div className="flex flex-col h-full justify-start md:w-full">
            <div className="hidden md:flex justify-start items-center pb-6">
            <i className="ri-arrow-left-line text-[24px] " onClick={()=>{
                  setIsOpen(false);
                }}/>
            </div>

            <span className="text-[32px]">Complete profile</span>

            <span className="text-[14px] pt-[1px]">Enter the below details to complete your profile.</span>

            <Input labelValue="Address" value={address} onChange={(e)=>{
              setAddress(e.target.value)
            }} className="mt-[40px] w-full"/>


            <div className="mt-3 flex flex-col">
              <div className='text-sm font-normal text-white pb-1'>Date of Birth</div>
            
              <DateInputPicker
               placeholder={dateFormat(new Date())}
                enabled={true}
                onChange={(e) => setBirthDate(e.target.value)}
                value={birthDate}
                allowEdit={false}
              />
            </div>

            <div className="mt-3">
              <div className='text-sm font-normal text-white pb-1'>Gender</div>

              <SimpleSelect 
                label="Gender"
                placeholder="Select..."
                value={gender} 
                onChange={(e)=>{
                  setGender(e)
                }}
                options={[{label: 'Male', value:'Male'}, {label: 'Female', value:'Female'}, {label: 'Other', value:'Other'}]} 
              />
            </div>

            <div className="mt-3">
              <div className='text-sm font-normal text-white pb-1'>Language preference</div>

              <SimpleSelect 
                label="Gender"
                placeholder="Select..."
                value={language}  
                onChange={(e)=>{
                  setLanguage(e)
                }}
                options={[{label: 'English', value:'English'}, {label: 'Sinhala', value:'Sinhala'}]} 
              />
            </div>

            
            <Button appearance="market-red" className="mt-[30px]" isBlock
              isLoading={updateUserProfileIsLoading}
              disabled={!address || !birthDate || !gender || !language}
              onClick={()=>{
                handleUpdateUserProfile()
              }}
            >Continue</Button>
          </div>
          
        </div>
    </Drawer>
  )
}

ProfileDrawer.defaultProps = {}

export default ProfileDrawer
